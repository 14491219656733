import React from 'react';
import {useTranslation} from 'PlattixUI/PlattixReactCore/i18n';
import {ResetPasswordForm} from "../../forms/account/ResetPasswordForm";
import {UserState} from "../../../PlattixReactCore/UserSlice";

export function ResetPasswordPage(params: {userState: UserState}) {
    const { t} = useTranslation(['translation']);
    return (
        <div className="login-form">
            <h5 className="login-title">{t('ForgotH4')}</h5>
            <ResetPasswordForm userState={params.userState}/>
        </div>
    );
}