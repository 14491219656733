import i18n, {TOptions} from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Backend from 'i18next-http-backend';
//To load the translation files

i18n.on('languageChanged', function (lng: string) {
    localStorage.setItem("lng", lng);
})

i18n.on('initialized', function (lng: string) {
    localStorage.setItem("lng", lng);
})

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        react: {
            bindI18n: 'languageChanged',
            bindI18nStore: 'added'
        },
        preload: ['nl'],
        // debug: process.env.NODE_ENV !== 'production',
        debug: false,
        fallbackLng: 'nl',
        //whitelist: ['en', 'nl', 'fr', 'de'],
        interpolation: {
            escapeValue: false,
        },
        ns: ['translation'],
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
            addPath:  `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}.json`,
        },
        detection: {
            order: ['cookie', 'localStorage'],
            lookupLocalStorage: 'lng',
            lookupCookie: 'UserCulture', // Use same cookie as backend
            //checkWhitelist: true,
            caches: ['localStorage', 'cookie'],
        },
        saveMissing: false,
        // saveMissingTo: 'all',
        keySeparator: false,
    }).then(() => {
        // console.log('translations initialized')
});

export function t(key: string | string[], options?: TOptions | string)
{
    if (Array.isArray(key)){
        return i18n.t(key.map(k => k.toLowerCase()), options)
    }
    return i18n.t(key.toLocaleLowerCase(), options);
}

export function useTranslation(ns?: string[])
{
    return {t:t}
}

export default i18n;