import {GridRenderCellParams, GridRowParams} from "@mui/x-data-grid";
import {GridColumnConfig} from "./types/GridTypes";
import {GridButton} from "PlattixUI/core/components/Buttons";
import {Link} from "react-router-dom";
import moment from "moment";
import {toFixedDecimal} from "PlattixUI/util/numberUtil";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    GridRowOption,
    GridRowOptions,
    isGridRowOptionAction,
    isGridRowOptionLink
} from "PlattixUI/core/grid/gridStyling/GridOptions";
import {GridActionsCellItem} from "@mui/x-data-grid-pro";
import {history} from "PlattixUI/PlattixReactCore/store";


type CellRenderer = ((cell: GridRenderCellParams) => React.ReactNode)

export function GetCellRenderer(column: GridColumnConfig): CellRenderer {
    switch (column.displayType) {
        case "hyperlink":
            return formatHyperlink(column);
        case "hyperlinkarray":
            return formatHyperlinkArray(column);
        case "image":
            return formatImage(column);
        case "checkbox":
            return formatCheckbox(column);
        case "datetime":
        case "timespan":
        case "date":
        case "time":
            return formatDateTime(column)
        case "decimal":
            return formatDecimal(column)
        case "currency":
            return formatCurrency(column)
        // case "editabletexteditor":
        //     return renderInputText(column)
        // case "ellipsis":
        //     return formatEllipsis(column);
        case "text":
        default:
            return formatNull;
    }
}

const formatNull: CellRenderer = (cell: GridRenderCellParams) => (cell.value === null ? <span className="nullCell"/> : cell.value);

function formatHyperlinkArray(column: GridColumnConfig) {
    return (cell: GridRenderCellParams) => {
        if (cell.value) {
            return cell.value.split(",").map(s => <div key={s}>{formatHyperlink(column, s)?.(cell)}</div>)
        }
        return formatNull(cell);
    }
}

function formatHyperlink(column: GridColumnConfig, valueOverwrite?: any): CellRenderer {
    const url = column.extensionUrl;
    const keyField = column.extensionKeyField;

    return (cell: GridRenderCellParams) => {
        const value = valueOverwrite ?? cell.value;

        if (url && keyField && value)
            return <GridLink url={url + cell.row[keyField]} label={ value} />
        if (!keyField && value.includes('|'))
            return <GridLink url={url + value.split('|')[0]} label={value.split('|')[1]} />
        return <GridButton>{cell.value}</GridButton>
    }
}

function GridLink(props: {url: any, label: any}) {
    const isLocalUrl = props.url.startsWith('http')
    if (isLocalUrl) {
        return <a href={props.url}><GridButton>{props.label}</GridButton></a>
    }
    return <Link to={props.url}><GridButton>{props.label}</GridButton></Link>
}

function formatImage(column: GridColumnConfig):CellRenderer {
    return (cell: GridRenderCellParams) => <span>Images not yet supported</span>
}

function formatCheckbox(column: GridColumnConfig):CellRenderer {
    return (cell: GridRenderCellParams) => <input type={"checkbox"} disabled checked={cell.value}/>
}

function formatDateTime(column: GridColumnConfig):CellRenderer {
    let format;

    switch (column.displayType) {
        case "datetime":
            format = "L LT";
            break;
        case "date":
            format = "L";
            break;
        case "time":
            format = "LT";
            break;
    }

    return (cell: GridRenderCellParams) => {
        if (cell.value)
            return moment(cell.value).format(format)
        return formatNull(cell)
    }
}

function formatDecimal(column: GridColumnConfig):CellRenderer {
    let numDecimals = 2;
    switch (column.format) {
        case "n":
            numDecimals = 2;
            break;
        case "n4":
            numDecimals = 4;
            break
    }
    return (cell: GridRenderCellParams) => toFixedDecimal(cell.value, numDecimals)
}

function formatCurrency(column: GridColumnConfig):CellRenderer {
    let numDecimals = 2;
    switch (column.format) {
        case "c":
            numDecimals = 2;
            break;
        case "c4":
            numDecimals = 4;
            break
    }
    return (cell: GridRenderCellParams) => '€' + toFixedDecimal(cell.value, numDecimals)
}


const formatEncrypted:CellRenderer = (cell: GridRenderCellParams) => <span className="encryptedCell">********</span>

export function GetRowOptions(options: GridRowOptions | undefined, row: GridRowParams) {
    return options
        ?.filter(option => option.showIf === undefined || option.showIf(row))
        .map(option => RowOptionComponent(option, row)) ?? [];
}

export function RowOptionComponent( option: GridRowOption, row: GridRowParams) {
    function onClick() {
        if (isGridRowOptionLink(option)) {
            let href;
            if (typeof option.href === "function") href = option.href(row);
            else href = option.href;

            history.push(href)
        }
        if (isGridRowOptionAction(option)) {
            option.onClick(row)
        }
    }

    function getIcon() {
        if (!option.icon) return
        return <FontAwesomeIcon icon={option.icon}/>
    }

    if (option.showInMenu ?? true)    
        return <GridActionsCellItem icon={getIcon()} onClick={onClick} label={option.label} showInMenu={true} />
    
    return <GridActionsCellItem icon={getIcon() ?? <></>} onClick={onClick} label={option.label} showInMenu={false} />
    
    

}
