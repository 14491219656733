import React, {SelectHTMLAttributes, useEffect, useState} from "react";
import {Controller, FieldError, FieldPath, UseFormRegisterReturn, UseFormReturn} from "react-hook-form";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import Select, {ControlProps, CSSObjectWithLabel, GroupBase, OptionProps, StylesConfig} from "react-select";
import {useTranslation} from "PlattixUI/PlattixReactCore/i18n";
import {doGet, throwOnHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {filterProps, filterProps as filterPropsDefault} from "PlattixUI/util/ElementProperties";
import {getErrorMessage} from "./formUtil";
import {ErrorMessage} from "../ActionBar";
import {useQuery} from "react-query";


export interface PlattixCodeSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
    label: string,
    namespace: string,
    tableName: string,
    onChange?: React.ChangeEventHandler<HTMLSelectElement>,
    register: UseFormRegisterReturn,
    error?: FieldError | string | string[] | undefined | HttpError | null
}


const selectStyling: StylesConfig<SelectOption, boolean, GroupBase<SelectOption>> = {

    menu: (provided: CSSObjectWithLabel, state) => ({
        margin: '6px 0 0 0',
        background: '#ffffff',
        borderRadius: 10,
        border: '3px solid rgba(0, 0, 0, 0.1)',
        width: '100%',
        position: 'absolute',
        zIndex: 10,
        boxShadow: 'var(--shadow2)',
        left: 0,
    }),
    menuList: (provided: CSSObjectWithLabel, state) => ({
        margin: 0,
        padding: '10px 0',
        background: '#ffffff',
        borderRadius: 10,
        cursor: 'pointer',
    }),
    option: (provided: CSSObjectWithLabel, state: OptionProps<SelectOption>): CSSObjectWithLabel => ({
        padding: '5px 15px',
        height: 'unset',
        background: state.isFocused ? '#eee' : state.isSelected ? 'var(--styleColor3)' : '',
        '&:hover, &:active': {
            background: '#eee',
        },
    }),
    valueContainer: (provided: CSSObjectWithLabel, state) => ({
        padding: '0 20px 0 0',
        borderRadius: 50,
        border: 'none',
        fontSize: '13px',
    }),
    input: (provided: CSSObjectWithLabel, state) => ({
        borderRadius: 50,
        border: 'none',
        fontSize: '13px',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        'input': {
            height: 25,
            borderRadius: 50,
        }
    }),
    indicatorsContainer: (provided: CSSObjectWithLabel, state) => ({
        width: '100%',
        height: 25,
        padding: 0,
        position: 'absolute',
        top: 0,
        left: 0,
    }),
    dropdownIndicator: (provided: CSSObjectWithLabel, state) => ({
        padding: '0 7px 0 0',
        height: 25,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    }),
    control: (provided: CSSObjectWithLabel, state: ControlProps<SelectOption>): CSSObjectWithLabel => ({
        // height: 25,
        // borderRadius: 10,
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        const margin = 0;

        return {...provided, opacity, transition, margin};
    },
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
        padding: 0,
        margin: 0,
    }),
}


export interface SelectOption {
    label: string,
    value: number | string | boolean,
    disabled?: boolean,
    selected?: boolean
}

export interface NumberSelectOption extends SelectOption {
    value: number
}

export interface StringSelectOption extends SelectOption {
    value: string
}

export interface BooleanSelectOption extends SelectOption {
    value: boolean
}

export function PlattixCodeSelect(props: PlattixCodeSelectProps) {
    const {t} = useTranslation(['translation']);

    const [Codes, setCodes] = React.useState<any[]>([]);

    const getCodes = async () => {
        const response = await doGet('/Code/PlattixSelectCodeList', {
            Namespace: props.namespace,
            TableName: props.tableName
        }).then((result: any[] | any) => {
            console.log(result);
            if (!result) return [];
            return result;
        });

        setCodes(response);
    };

    React.useEffect(() => {
        if (Codes.length === 0) {
            getCodes();
        }
    }, [Codes.length, getCodes, props.tableName]);

    return (
        <div className="form-group row">
            <div className="module-tab-content-body-group">
                <label className="module-tab-content-title"
                       htmlFor={props.id ?? props.name}>
                    {t(props.label)}
                </label>

                <Select
                    {...filterProps(props, ['label', 'namespace', 'tableName', 'register', 'error'])}
                    className="form-control"
                    id={props.id ?? props.name}
                    options={Codes}
                    isMulti={false}
                    styles={selectStyling}
                />

                {(props.error) &&
                    <span className="text-danger">{getErrorMessage(props.register.name, props.error)}</span>}
            </div>
        </div>
    )
}

export interface PlattixFormSelectProps<TFieldValues> extends PlattixSelectProps<TFieldValues>{
    label: string,
    error?: FieldError | string | string[] | undefined | HttpError | null,
}


/**
 * Basic Plattix Select component with label end error
 * 
 * If you dont need the label, use {@link PlattixSelect}
 * @param props
 */
export function PlattixFormSelect<T>(props: PlattixFormSelectProps<T>) {
    const {t} = useTranslation()

    const id = props.id ?? props.name;

    return (
        <div className="form-group row">
            <div className="module-tab-content-body-group">
                <label className="module-tab-content-title" htmlFor={id}>
                    {t(props.label)}
                </label>

                <PlattixSelect {...props} />

                {
                    (getErrorMessage(props.name, props.error) || props.form.getFieldState(props.name).error)
                    && <span
                        className="text-danger">{getErrorMessage(props.name, props.error) ?? getErrorMessage(props.form.register.name, props.form.getFieldState(props.name).error)}</span>
                }
            </div>
        </div>
    );
}


export interface PlattixSelectProps<TFieldValues> {
    name: FieldPath<TFieldValues>,
    options: SelectOption[] | undefined,
    form: UseFormReturn<TFieldValues, object>,
    placeholder?: string,

    /**
     * Add an unselectable option that forces the user to pick an option
     */
    chooseOption?: boolean,
    /**
     * Should the 'choose Option' option be unselectable
     */
    chooseOptionDisabled?: boolean,

    id?: string,
    required?: boolean,
    allowZero?: boolean,
}
/**
 * Basic Plattix Select component without label end error.
 * 
 * If you need the label and error use {@link PlattixFormSelect}
 * @param props
 */
export function PlattixSelect<T>(props: PlattixSelectProps<T>){
    const {t} = useTranslation()
    const [initialized, setInitialized] = useState(false)

    const id = props.id ?? props.name;

    const options = addChooseOption()

    function addChooseOption(){
        const options = props.options ?? [];
        if (props.chooseOption && options.length > 0){
            return [{label: t('ChooseOption'), value: '', disabled:props.chooseOptionDisabled}, ...options]
        }

        return options;
    }

    const initialValue = (value) => {
        if (value === null || value === undefined) return options.find((option) => option.selected)
            ?? options[0];

        const val = options.find((option) => value == option.value)
        if (!val) console.error(`optie met id ${value} niet gevonden`)
        return val ?? options[0];
    }

    function checkRequired(value, formOnchange) {
        if (props.required) {
            if (!value || value === 0 || value === '0') {
                props.form.setError(props.name, {message: t('Validation.Error.Required'), type: 'required'})
            } else {
                props.form.clearErrors(props.name)
            }
        }

        formOnchange(value)
    }

    useEffect(() => {
        if (!initialized) {
            if (options.length > 0) {
                props.form.setValue(props.name, initialValue(props.form.getValues(props.name)).value as any)
                setInitialized(true)
            }
        }
    }, [initialized]);
    
    props.form.register(props.name, {required: props.required});
    
    return (
        <Controller
        name={props.name}
        control={props.form.control}
        render={({field: {value, onChange, onBlur}}) => {
            return (
                <Select
                    id={id}
                    options={addChooseOption()}
                    placeholder={props.placeholder}
                    isMulti={false}
                    onChange={(option) => checkRequired(option?.value, onChange)}
                    onBlur={onBlur}
                    value={initialValue(value)}
                    defaultValue={initialValue(value)}
                    styles={selectStyling}
                    isOptionDisabled={(option) => option.disabled ?? false}
                    isOptionSelected={(option) => option.selected ?? false}
                    className={'form-control'}
                    // formatOptionLabel={formatOptionLabel}
                    menuPortalTarget={document.body}

                />
            );
        }}
    />
    )
}

interface PlattixCodeFormSelectProps<TFieldValues> {
    name: FieldPath<TFieldValues>,
    label: string,
    form: UseFormReturn<TFieldValues, object>,
    placeholder?: string,

    /**
     * Add an unselectable option that forces the user to pick an option
     */
    chooseOption?: boolean,
    /**
     * Should the 'choose Option' option be unselectable
     */
    chooseOptionDisabled?: boolean,

    id?: string,

    namespace?: string,
    tableName: string
}

export function PlattixCodeFormSelect<TFieldValues>(props: PlattixCodeFormSelectProps<TFieldValues>) {
    const namespace = props.namespace ?? "DataAccessLayer.Data"

    const [initialized, setInitialized] = useState(false);
    const codes = useQuery<SelectOption[]>(['codeSelect', namespace, props.tableName],
        () => throwOnHttpError(doGet<SelectOption[]>(
            '/Code/PlattixSelectCodeList',
            {
                Namespace: props.namespace,
                TableName: props.tableName
            }
        )),
        {
            initialData: []
        }
    )
    
    useEffect(() => {
        if (!initialized && codes.data?.length) {
            const getInitialValue = (value) => {
                const options = codes.data ?? [];
                if (value === null || value === undefined) 
                    return options.find((option) => option.selected)?? options[0];

                // eslint-disable-next-line eqeqeq
                const val = options.find((option) => value == option.value)
                if (!val) console.error(`optie met id ${value} niet gevonden`)
                return val ?? options[0];
            }
            
            props.form.setValue(props.name, getInitialValue(props.form.getValues(props.name)).value as any )
            setInitialized(true)
        }
    }, [codes, initialized, props.form, props.name])

    if (codes.isError) return <ErrorMessage>Kon codes niet ophalen</ErrorMessage>

    return <PlattixFormSelect
        {...filterPropsDefault(props, ['namespace', 'tablename'])}
        chooseOption={props.chooseOption}
        form={props.form}
        name={props.name}
        label={props.label}
        options={codes.data}/>
}


// const formatOptionLabel = ({ label, value }) => {
//     console.log(label)
//     return (
//     <div style={{ display: "flex" }}>
//         <div>dslkfj</div>
//         <div style={{ marginLeft: "10px", color: "#ccc" }}>
//             {label}
//         </div>
//     </div>
// )};