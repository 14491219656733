import {FieldPath, UnpackNestedValue, useForm, UseFormReturn} from "react-hook-form";
import {Validator} from "../../forms/FormValidators";
import Modal, {ModalBaseProps} from "../Modal";
import React, {FormHTMLAttributes, useState} from "react";
import {HttpError} from "../../../PlattixReactCore/CoreTypes";
import {doPost, isHttpError} from "../../../PlattixReactCore/api/Api";
import {
    NumberFormatOptions,
    PlattixFormSuffixType,
    PlattixInputTypes,
    PlattixSubmitButton,
    PlattixValidatedInput,
    PlattixValidatedInputProps
} from "./Input";
import {ErrorMessage} from "../ActionBar";
import {filterProps} from "../../../util/ElementProperties";
import {CancelButton} from "../Buttons";
import {useTranslation} from "../../../PlattixReactCore/i18n";
import {SelectOption} from "PlattixUI/core/components/form/Select";


interface PlattixFormProps extends FormHTMLAttributes<HTMLFormElement> {
    onSubmit?: React.FormEventHandler<HTMLFormElement>,
    autocomplete?: string,
}

export function PlattixForm(props: React.PropsWithChildren<PlattixFormProps>) {
    return (
        <form {...props} onSubmit={props.onSubmit}>
            <div className="login-content-group-container-top">
                {props.children}
            </div>
        </form>
    );
}

export interface PlattixFormModalProps extends PlattixFormProps {
    submitButtonText?: string,
    onClose: () => void,
    show: boolean,
    loading: boolean
}

export function PlattixFormModal(props: React.PropsWithChildren<PlattixFormModalProps>) {
    const {t} = useTranslation()

    const submitBtnText = props.submitButtonText ?? t('Save') ?? 'Save'
    return <Modal
        onClose={props.onClose} show={props.show}
        showConfirmButton={false}
        customButton={<PlattixSubmitButton form={"ModalForm"} name={submitBtnText} loading={props.loading}/>}
        closeOnOutsideClick={false}
        loading={props.loading}
        title={props.title}
    >
        <PlattixForm id={"ModalForm"} {...filterProps(props, ['submitButtonText', 'onCLose', 'show', 'loading'])} >
            {props.children}
        </PlattixForm>
    </Modal>
}

export type NumberFormatPlattixSubmitField = {
    type: "number-format",
    numberFormatOptions: NumberFormatOptions
    options?: undefined,
}
export type SelectPlattixSubmitField = {
    type: "select",
    numberFormatOptions?: undefined,
    options: SelectOption[],
}
export type BasePlattixSubmitField = {
    type?: Exclude<PlattixInputTypes, 'number-format' | 'select'>,
    numberFormatOptions?: undefined,
    options?: undefined,
}

export type PlattixSubmitField<TModel> = {
    label: string,
    name: FieldPath<TModel>,
    validation?: Validator<TModel> | Validator<TModel>[],
    readonly?: boolean,

    showIf?: FieldPath<TModel>[],

    description?: string | JSX.Element,
    suffix?: PlattixFormSuffixType,
} & (
    NumberFormatPlattixSubmitField |
    SelectPlattixSubmitField|
    BasePlattixSubmitField
)

export interface PlattixSubmitFormModalProps<TModel, TResponse> extends ModalBaseProps {
    // id: number | string,
    title: string,
    fields: PlattixSubmitField<TModel>[],
    defaultValue?: TModel,
    postUrl: string,
    onSuccess?: (model: TResponse) => void,
}

export function PlattixSubmitFormModal<TModel, TResponse>(props: PlattixSubmitFormModalProps<TModel, TResponse>) {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<HttpError | null>(null)

    const form = useForm<TModel>({defaultValues: props.defaultValue as any})

    async function onSubmit(model) {
        setLoading(true)

        const response = await doPost<TResponse>(props.postUrl, model)

        if (isHttpError(response)) {
            setError(response)
        } else {
            setError(null)

            if (props.onSuccess) props.onSuccess(response)

            props.onClose()
        }

        setLoading(false)
    }

    return <PlattixFormModal
        // key={props.id}
        title={props.title}
        onSubmit={form.handleSubmit(onSubmit)}
        show={props.show}
        onClose={props.onClose}
        loading={loading}
    >
        {error?.title &&
            <ErrorMessage>{error?.title}</ErrorMessage>
        }

        {props.fields.map(field => {
            return <PlattixSubmitFieldComponent key={field.name} field={field} form={form} error={error} />
        })}

    </PlattixFormModal>
}

export function PlattixSubmitFieldComponent<TModel>(props: {
    field: PlattixSubmitField<TModel>, 
    form: UseFormReturn<TModel, object>,
    error: HttpError | null | undefined,
    readOnly?: boolean
}){
    const fieldProps: PlattixValidatedInputProps<TModel> = {
        name:props.field.name,
        label:props.field.label,
        formHook: props.form,
        validation:props.field.validation,
        type:"text",
        error: props.error,
        description:props.field.description,
        suffix:props.field.suffix,
        readonly: props.readOnly || props.field.readonly
    }
    if (props.field.type === 'number-format'){
        return <PlattixValidatedInput<TModel>
            key={props.field.name}
            {...fieldProps}
            type={'number-format'}
            numberFormatOptions={props.field.numberFormatOptions}
        />
    } else if(props.field.type === 'select'){
        return <PlattixValidatedInput
            key={props.field.name}
            {...fieldProps}
            type={'select'}
            options={props.field.options}
        />
    } else {
        return <PlattixValidatedInput<TModel>
            key={props.field.name}
            {...fieldProps}
            type={props.field.type}
        />
    }
}

export interface PlattixAutoFormProps<TModel> {
    formName?: string,
    /**
     * List of fields to include in the form
     */
    fields: PlattixSubmitField<TModel>[],
    /**
     * Object containing the default form values.
     */
    defaultValue?: TModel,
    readonly?: boolean,
    loading?: boolean,

    showSubmitButton?: boolean,
    onSubmit?: (model: TModel) => void,
    SubmitButtonText?: string,

    showCancelButton?: boolean,
    onCancel?: () => void,
    CancelButtonText?: string,

    /**
     * Should the form be reset if the Cancel button is clicked
     */
    resetOnCancel?: boolean,

    error?: HttpError | null,

    showIf?: FieldPath<TModel>[],

}

/**
 * Generate a form for the specified fields
 * @param props
 */
export function PlattixAutoForm<TModel>(props: PlattixAutoFormProps<TModel>) {
    const {t} = useTranslation();
    const form = useForm<TModel>({defaultValues: props.defaultValue as any})

    async function onSubmit(model: UnpackNestedValue<TModel>) {
        await props.onSubmit?.(model as TModel)
    }

    async function onCancel() {
        if (props.resetOnCancel ?? true) {
            form.reset()
        }
        props.onCancel?.()
    }

    return <PlattixForm
        name={props.formName}
        onSubmit={form.handleSubmit(onSubmit)}
    >

        {props.error?.title &&
            <ErrorMessage>{props.error?.title}</ErrorMessage>
        }

        {props.fields.map(field => {
            return <PlattixSubmitFieldComponent<TModel>
                key={field.name}
                field={field}
                form={form}
                error={props.error}
                readOnly={props.readonly}
            />
            
            const fieldProps = {
                name:field.name,
                label:field.label,
                formHook:form,
                validation:field.validation,
                type:field.type,
                error:props.error,
                readOnly:props.readonly || field.readonly,
                showIf:props.showIf,
                description:field.description,
                suffix:field.suffix,
                numberFormatOptions: field.numberFormatOptions
            }            
            return <PlattixValidatedInput<TModel>
                key={field.name}
                {...(fieldProps as PlattixValidatedInputProps<TModel>)}
            />
        })}

        {!props.readonly && form.formState.isDirty && <div className={'module-content-tab-btns'}>
            {(props.showCancelButton ?? true) &&
                <CancelButton disabled={props.loading}
                              onClick={onCancel}>
                    {props.CancelButtonText ?? t('Cancel')}
                </CancelButton>
            }
            {
                (props.showSubmitButton ?? true) &&
                <PlattixSubmitButton loading={props.loading} name={props.SubmitButtonText ?? t("Save")}/>
            }
        </div>
        }
    </PlattixForm>

}

export interface PlattixSubmitFormProps<TModel, TResponse> extends PlattixAutoFormProps<TModel> {
    /**
     * Url to post form data to
     */
    postUrl: string,
    /**
     * Callback function that is called when the form was successfully submitted
     * @param model: The server response
     */
    onSuccess?: (model: TResponse) => void,
}

/**
 * Generate a PlattixAutoForm that will post the form to the postUrl provided in the properties
 * @param props
 * @constructor
 */
export function PlattixSubmitForm<TModel, TResponse>(props: PlattixSubmitFormProps<TModel, TResponse>) {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<HttpError | null>(null)

    async function onSubmit(model) {
        setLoading(true)

        const response = await doPost<TResponse>(props.postUrl, model)

        if (isHttpError(response)) {
            setError(response)
        } else {
            setError(null)

            if (props.onSuccess) props.onSuccess(response)
        }

        setLoading(false)
    }

    return <PlattixAutoForm
        {...props}
        onSubmit={onSubmit}
        loading={loading}
        error={error}
    />

}