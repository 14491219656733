import {css} from "goober";

// export const TextSection = styled('div')((props) => {
//     return `
//        
//     `
// });

// export const PlattixDataGrid = css`
//     width: 100%;
// `;


export const dataGridStyling = {
    width: '100%',
    border: 'none',
    '.MuiDataGrid-main': {
        // 'border-top': 'var(--borderBottom1)',
        // 'margin-top': '25px',
    },
    '.MuiCollapse-root': {
        'borderBottom': 'var(--borderBottom1)',
    },
    '.MuiDataGrid-toolbarContainer': {
        'borderBottom': 'var(--borderBottom1)',
        'marginBottom': '15px',
        'gap': '15px',
        'paddingBottom': '10px',
    },
    '.MuiButton-root': {
        'fontFamily': 'var(--defaultFontFamilyText)',
        'textTransform': 'none',
        'color': 'unset',
        'display': 'flex',
        'justifyContent': 'center',
        'lineHeight': 'unset',
        'fontSize': '1em',
    },
    '.MuiButton-root:hover': {
        'backgroundColor': '#f5f5f5',
    },
    '.MuiDataGrid-filterForm': {
        'width': '100%',
        'gap': '10px',
        '.MuiDataGrid-filterFormColumnInput, .MuiDataGrid-filterFormOperatorInput, .MuiDataGrid-filterFormValueInput': {
            'width': '100%',
            '.MuiBox-root': {
                'padding': 0,
                'width': '100%',
                '.MuiFormControl-root': {
                    'width': '100%',
                }
            },
        },
    },
    '.MuiDataGrid-panelFooter': {
        'justifyContent': 'flex-end',
        'padding': '10px 0',
    },
    '.MuiDataGrid-columnHeaders': {
        'background': '#f5f5f5',
        'borderRadius': '10px',
    },
}

export const DataGridToolButton = css`
    cursor: pointer;
    fontFamily: var(--defaultFontFamilyText);
    textTransform: none;
    color: unset;
    display: flex;
    justifyContent: center;
    lineHeight: unset;
    fontSize: 1em;
    border: none;
    padding: 4px 5px;
    background: none;
    borderRadius: 4px;
    gap: 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition-property: background-color, box-shadow, border-color, color;
    transition-duration: 250ms, 250ms, 250ms, 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms, 0ms, 0ms, 0ms;
    
    &:hover {
        background-color: #f5f5f5;
    }
`;

export const DataGridHeader = css`
    background: green;
`;