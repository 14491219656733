import React, {InputHTMLAttributes, useEffect, useRef, useState} from "react";
import {
    Controller,
    FieldError,
    FieldPath,
    Path,
    PathValue,
    UseFormRegisterReturn,
    UseFormReturn,
    ValidateResult
} from "react-hook-form";
import {HttpError} from "../../../PlattixReactCore/CoreTypes";
import {getErrorMessage} from "./formUtil";
import {filterProps} from "../../../util/ElementProperties";
import NumberFormat, {FormatInputValueFunction, NumberFormatValues} from "react-number-format";
import {getDecimalSeparator, getThousandSeparator} from "PlattixUI/PlattixReactCore/types/Languages";
import htmlRaw from "PlattixUI/util/HtmlRaw";
import {Collapse} from "@mui/material";
import {ConfirmButton} from "PlattixUI/core/components/Buttons";
import {Validator} from "PlattixUI/core/forms/FormValidators";
import {t, useTranslation} from "../../../PlattixReactCore/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {PlattixSelect, SelectOption} from "PlattixUI/core/components/form/Select";
import {
    BasePlattixSubmitField,
    NumberFormatPlattixSubmitField,
    SelectPlattixSubmitField
} from "PlattixUI/core/components/form/Form";

/**
 * Default html input types supported by modern browsers
 */
type DefaultInputTypes =
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';

export type PlattixInputTypes =
    | DefaultInputTypes
    | "textarea"
    | "number-format"
    | "select"

interface PlattixInputBaseProps extends InputHTMLAttributes<HTMLInputElement> {
    type?: PlattixInputTypes
    label: string,
    // onChange?: React.ChangeEventHandler<HTMLInputElement>,
    error?: FieldError | string | string[] | undefined | HttpError | null,

    description?: JSX.Element | string,
    suffix?: PlattixFormSuffixType,

    readOnly?: boolean,
}

/**
 * Number formatting options for number-format inputs
 *
 * These options are a subset form the component
 * see full docs at {@link https://www.npmjs.com/package/react-number-format}
 */
export type NumberFormatOptions = {
    /**
     * How many decimals to show
     */
    decimalScale: number;
    /**
     * Always show {@see decimalScale} decimals even if all zeros
     * default true
     */
    fixedDecimalScale?: boolean;
    /**
     * Use custom thousands separator
     * default is fetched from user locale
     */
    thousandSeparator?: boolean | string;
    /**
     * Use custom decimal separator
     * default is fetched from user locale
     */
    decimalSeparator?: string;
    /**
     * prefix to prepend on formatted value
     */
    prefix?: string;

    suffix?: string;
    format?: string | FormatInputValueFunction;
    removeFormatting?: (formattedValue: string) => string;
    mask?: string | string[];
    isNumericString?: boolean;
    allowNegative?: boolean;
    allowEmptyFormatting?: boolean;
    allowLeadingZeros?: boolean;
    type?: 'text' | 'tel' | 'password';
    isAllowed?: (values: NumberFormatValues) => boolean;
}

/**
 * Properties needed to register a controlled number-format input
 * {@see https://www.npmjs.com/package/react-number-format}
 */
interface NumberFormatProps extends PlattixInputBaseProps {
    type: "number-format",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn,
    /**
     * Number formatting options
     */
    numberFormatOptions: NumberFormatOptions
}

/**
 * Properties needed to register a controlled number-format input
 * {@see https://www.npmjs.com/package/react-number-format}
 */
interface SelectInputProps extends PlattixInputBaseProps {
    type: "select",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn<any, object>,
    /**
     * Number formatting options
     */
    options: SelectOption[]
}

/**
 * Properties for a normal registered Plattix input
 */
interface RegisteredProps extends PlattixInputBaseProps {
    register: UseFormRegisterReturn,
    type?: Exclude<PlattixInputTypes, "number-format" | "select">
    name?: string,
}

export type PlattixInputProps = NumberFormatProps | RegisteredProps | SelectInputProps


/**
 * Filter out non-default html properties from Props so they are not passed to the component
 * @param props
 */
function filterFormProps(props: any) {
    const invalidHtmlInputAttrs = ['label', 'formHook', 'validation', 'register', 'numberFormatOptions']
    return filterProps(props, invalidHtmlInputAttrs)
}

/**
 * Creates a controled Number Format input
 * @param props
 * @constructor
 */
function PlattixNumberFormatInput(props: NumberFormatProps) {
    return <Controller
        render={({field}) => {
            if (props.type === 'number-format' && props.formHook && props.name)
                return (
                    <NumberFormat
                        {...field}
                        onValueChange={(c) => {
                            field.onChange(c.value);
                        }}
                        {...props.numberFormatOptions}
                        fixedDecimalScale={props.numberFormatOptions?.fixedDecimalScale ?? true}
                        thousandSeparator={props.numberFormatOptions?.thousandSeparator ?? getThousandSeparator()}
                        decimalSeparator={props.numberFormatOptions?.decimalSeparator ?? getDecimalSeparator()}
                        thousandsGroupStyle={"thousand"}
                    />
                );
            return <></>
        }}
        name={props.name}
        control={props.formHook.control}
    />
}

export function PlattixInput(props: PlattixInputProps) {

    function getInputErrorMessage() {
        let name;
        if (props.type === 'number-format' || props.type === 'select') name = props.name
        else name = props.register.name
        return getErrorMessage(name, props.error)
    }
    
    function renderInput(){
        switch (props.type) {
            case ('number-format'): 
                return <PlattixNumberFormatInput {...props} />
            case ('select'): 
                return <PlattixSelect<any> name={props.name} form={props.formHook} options={props.options} />
            case ('textarea'): 
                return  <textarea
                {...filterFormProps(props)}
                className="form-control"
                id={props.id ?? props.name}
                defaultValue={props.value}
                placeholder={props.placeholder ?? props.label ?? props.name}
                cols={4}
                {...props.register}
            />
            default: 
                return <input
                {...filterFormProps(props)}
                type={props.type ?? "string"}
                className="form-control"
                id={props.id ?? props.name}
                defaultValue={props.value}
                placeholder={props.placeholder ?? props.label ?? props.name}
                {...props.register}
            />
        }
    }

    const {t} = useTranslation(['translation']);
    if (props.type === "hidden") {
        return (<div>
            <div>
                <input
                    {...filterFormProps(props)}
                    type={props.type}
                    className="form-control"
                    id={props.id ?? props.name}
                />
            </div>
        </div>);
    } else {
        return (
            <div className="form-group row">

                <label className="module-tab-content-title"
                       htmlFor={props.id ?? props.name}>
                    {t(props.label)}
                </label>
                <div className="module-tab-content-body-group">
                    {
                        renderInput()
                    }

                    {props.suffix && <PlattixFormSuffix type={props.suffix}/>}

                    {(props.error) && <span className="text-danger">{getInputErrorMessage()}</span>}

                    {props.description && <div className="fromInputDescription">{props.description}</div>}
                </div>
            </div>
        );
    }
}

export function PlattixCheckbox(props: RegisteredProps) {
    const {t} = useTranslation(['translation']);
    return (
        <div className="form-group row">
            <div className="module-tab-content-body-group">

                <input
                    {...filterFormProps(props)}
                    type={"checkbox"}
                    className="form-control"
                    id={props.id ?? props.name}
                    defaultValue={props.value}
                    placeholder={props.placeholder ?? props.name}
                    {...props.register}
                />
                <label className="module-tab-content-title"
                       htmlFor={props.id ?? props.name}>
                    {htmlRaw(t(props.label))}
                </label>

                {(props.error) &&
                    <span className="text-danger">{getErrorMessage(props.register.name, props.error)}</span>}

            </div>
        </div>
    );
}


export interface PlattixSubmitButtonProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string,
    loading?: boolean,
    form?: string,
}

export function PlattixSubmitButton(props: PlattixSubmitButtonProps) {
    return (
        <div className="module-content-tab-btns-2">
            <ConfirmButton disabled={props.disabled} loading={props.loading} type="submit"
                           form={props.form}>{props.name}</ConfirmButton>
        </div>
    )
}


export type PlattixValidatedInputProps<TFieldValues> = PlattixInputBaseProps & {
    formHook: UseFormReturn<TFieldValues, object>,
    name: FieldPath<TFieldValues>,
    validation?: Validator<TFieldValues> | Validator<TFieldValues>[],
    showIf?: FieldPath<TFieldValues>[],
    readonly?: boolean,
}
& (
    | NumberFormatPlattixSubmitField 
    | SelectPlattixSubmitField 
    | BasePlattixSubmitField
)


export function PlattixValidatedInput<TFieldValues>(props: PlattixValidatedInputProps<TFieldValues>) {
    const {formState: {errors}} = props.formHook;

    const [display, setDisplay] = useState(true)

    let validation: {
        [key: string]: (value: PathValue<TFieldValues, Path<TFieldValues>>)
            => ValidateResult | Promise<ValidateResult>
    } = {}

    useEffect(() => {
        if (!props.showIf) return;

        setDisplay(props.showIf.every(f => !!props.formHook.getValues(f)))

    });


    if (props.validation) {
        if (Array.isArray(props.validation)) {
            props.validation.forEach(v => v._register(props))
            validation = Object.fromEntries(props.validation.map(v => [v.name, (x) => v.validate(x)]))
        } else if (props.validation instanceof Validator) {
            const validator = props.validation;
            validator._register(props);
            validation[props.validation.name] = (v) => validator.validate(v);
        }
    }

    function errorMessage() {
        return getErrorMessage(props.name, props.error ?? errors[props.name as string])
            ?? props.formHook.getFieldState(props.name).error?.message
    }

    const dateTypes: PlattixInputTypes[] = ["date", "datetime-local"]
    const valueAsDate = dateTypes.includes(props.type ?? 'text')
    const numberTypes: PlattixInputTypes[] = ["number", "number-format"]
    const valueAsNumber = numberTypes.includes(props.type ?? 'text')

    return (
        <Collapse in={display}>
            <PlattixInput
                {...filterProps(props, ['showIf'])}
                register={props.formHook.register(props.name, {
                    validate: validation,
                    valueAsNumber: valueAsNumber,
                    valueAsDate: valueAsDate
                })}
                formHook={props.formHook as UseFormReturn}
                numberFormatOptions={props.type === 'number-format' ? props.numberFormatOptions : undefined}
                label={props.label}
                error={errorMessage()}
            />
        </Collapse>
    );
}

export async function ValidateField<TFieldValues>(form: UseFormReturn<TFieldValues, object>, field: PlattixValidatedInputProps<TFieldValues>) {
    let fieldValid = true

    const fieldValue = form.getValues(field.name)
    if (Array.isArray(field.validation)) {

        for (let i = 0; i < field.validation.length; i++) {
            const valid = await field.validation[i].validate(fieldValue)
            if (!valid) addFormError(form, field.name, field.validation[i].name)
            fieldValid = fieldValid && !!valid
        }
    } else if (field.validation) {
        fieldValid = !!await field.validation.validate(fieldValue)
        if (!fieldValid) addFormError(form, field.name, field.validation.name)
    }


    return fieldValid
}

export function addFormError<TFieldValues>(form: UseFormReturn<TFieldValues, object>, name: FieldPath<TFieldValues>, error: string | null | undefined) {
    if (error)
        form.setError(name, {message: error})
    else
        form.clearErrors(name)
}

export type PlattixFormSuffixType = 'euro' | 'percent' | 'W' | 'kW' | 'kWh' | 'year' | 'm2' | 'Wp' | 'kWp'

export function PlattixFormSuffix(props: { type: PlattixFormSuffixType }) {
    const {t} = useTranslation()
    if (props.type === 'year')
        return <span className="suffix">{t('Years')}Jaar</span>

    return <span className={`suffix-${props.type}`}/>
}

interface SearchInputProps {
    id?: string,
}

export function SearchInput(props: SearchInputProps) {
    const searchInput = useRef<HTMLInputElement>(null);
    const [searchValue, setSearchValue] = useState<string | undefined>('');
    
    const searchHandler = (e) => {
        setSearchValue(searchInput.current?.value)
        // console.log('Search:', searchInput.current?.value, searchValue)
    };

    return (
        <div className="module-content-search">
            <input
                className="searchBar"
                type="text"
                id={props.id}
                placeholder={t('Search')}
                onInput={searchHandler}
                ref={searchInput}
            />
            <button onClick={searchHandler}>
                <FontAwesomeIcon icon={faSearch} name={'searchIcon'}/>
            </button>
        </div>
    );
}