import {store} from "PlattixUI/PlattixReactCore/store";

export const Dutch = {
    code: "nl",
    name: "Nederlands",
    locale: "nl-be",
    thousandSeparator: ",",
    decimalSeparator: "."
}
export const English = {
    code: "en",
    name: "English",
    locale: "en",
    thousandSeparator: ".",
    decimalSeparator: ","
}
export const French = {
    code: "fr",
    name: "Français",
    locale: "fr",
    thousandSeparator: ",",
    decimalSeparator: "."
}
export const German = {
    code: "de",
    name: "Deutsch",
    locale: "de",
    thousandSeparator: ",",
    decimalSeparator: "."
}

export type Language = {
    code: string,
    name: string,
    locale: string,
    thousandSeparator: string,
    decimalSeparator: string,
}

export const SupportedLanguages: Language[] = [Dutch, French, German, English]

export function getActiveLanguage(){
    const userLang = store.getState()?.user?.language.toLowerCase() ?? 'nl'
    return SupportedLanguages.find(lang => lang.code === userLang)
}

export function getThousandSeparator(){
    return getActiveLanguage()?.thousandSeparator
}

export function getDecimalSeparator(){
    return getActiveLanguage()?.decimalSeparator
}