import React, {useState} from 'react';
import {useTranslation} from '../../../PlattixReactCore/i18n';
import {UserState} from "../../../PlattixReactCore/UserSlice";
import {PlattixInput, PlattixSubmitButton} from "../../components/form/Input";
import {useForm} from "react-hook-form";
import {Spinner} from "../../components/Loader";
import {ForgotPasswordModel, request2faReset} from "../../../PlattixReactCore/api/AccountApi";
import {isHttpError} from "../../../PlattixReactCore/api/Api";
import {CancelButton} from "../../components/Buttons";
import {Link} from "react-router-dom";
import {ContentCardButtons} from "../../components/ContentCard";
import {HttpError} from "../../../PlattixReactCore/CoreTypes";
import {PlattixForm} from "../../components/form/Form";

export function Reset2FAPage(params: { userState: UserState }) {
    const {register, handleSubmit, watch, formState: {errors}} = useForm<ForgotPasswordModel>();

    const [loading, setLoading] = useState(false);
    const [resetSuccess, setresetSuccess] = useState(false);
    const [resetError, setResetError] = useState<HttpError | null>(null);

    const {t} = useTranslation(['translation']);

    async function onSubmit(model: ForgotPasswordModel) {
        setLoading(true)

        const response = await request2faReset(model);

        setLoading(false);
        if (isHttpError(response)) {
            setResetError(response);
        } else {
            setresetSuccess(true);
        }
    }

    if (resetSuccess) {
        return (
            <div className="login-form">
                <h5 className="login-title">{t('Email.TwoFactorConfirmationToUser.CheckText')}</h5>
                <Link to={'/'}><CancelButton>{t('Link.To.Login')}</CancelButton></Link>
            </div>
        )
    }

    return (
        <div className="login-form">

            <div>
                <h5 className="login-title">{t('Forgot2FAH4')}</h5>
                {/*<p>{t('Reset2FA.Reset')}</p>*/}
            </div>
            
            <PlattixForm onSubmit={handleSubmit(onSubmit)}>
                <PlattixInput
                    disabled={loading}
                    type={"email"}
                    name="Email"
                    register={register("email", {required: true})}
                    label={t('Email')}
                    placeholder={t('EmailPH')}
                    error={errors.email}
                />

                {loading && <Spinner/>}

                <ContentCardButtons>
                    <Link to={"/"}><CancelButton/></Link>
                    <PlattixSubmitButton disabled={loading} name={t('Reset2FA')}/>
                </ContentCardButtons>
                
            </PlattixForm>
            
            {
                !!resetError &&
                <div className="login-content-group error-box">
                    <p className="login-error">{resetError.errors?.["_global"]}</p>
                </div>
            }

        </div>
    );
}