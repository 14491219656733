import {t} from "i18next";
import React, {PropsWithChildren, useEffect, useRef, useState} from "react";
import {LiveChatWidget} from '@livechat/widget-react';

import logo from "../../images/branding/lovitas/logo/logo.svg";
import bochtGroen from "../../images/branding/lovitas/details/Bocht-Groen.svg";
import bochtGeel from "../../images/branding/lovitas/details/Bocht-Geel.svg";
import bochtGrijs from "../../images/branding/lovitas/details/Bocht-Grijs.svg";
import backgroundEnergytix from "../../PlattixUI/images/branding/energytix/background/login.png";
import logoEnergytix from "../../PlattixUI/images/branding/energytix/logo/logo.svg";
import backgroundTactix from "../../PlattixUI/images/branding/tactix/background/login.png";
import logoTactix from "../../PlattixUI/images/branding/tactix/logo/logo.svg";
import imgIndustrialArea from "../../images/branding/lovitas/background/vogelvlucht.jpg";

import {
    AccountInfoContainer, AccountInfoField,
    AppCard,
    AppCardBody, AppCardButtons,
    AppCardHeader,
    BackgroundDetails,
    BackgroundMenu,
    BDetail,
    BDetailHorizontal,
    BDetailVertical,
    BodySection,
    closeButton,
    ColumnGroups,
    Header, HeaderImage, HeaderText,
    HeadSection,
    LandingPageContainer,
    LandingPageLogo,
    LoginFormContainer,
    LoginSection, LoginSliderContainer,
    Menu,
    MenuSection, NotificationCardBody, NotificationItem,
    Section, SectionSpacer, SidebarComponentsContainer,
    TextContainer,
    TextSection
} from "./LandingpageStyling";
import {useSelector} from "react-redux";
import {userSelector, UserState} from "../../PlattixUI/PlattixReactCore/UserSlice";
import {Link, Route, Switch, useHistory} from "react-router-dom";
import {ConfirmButton, LogoutButton} from "../../PlattixUI/core/components/Buttons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBolt,
    faBrain, faBullseye, faClipboardCheck,
    faEthernet, faFire,
    faLightbulb, faPiggyBank,
    faPlugCircleBolt,
    faRightFromBracket,
    faSun,
    faTimes, faUserGroup, faUsers
} from "@fortawesome/free-solid-svg-icons";
import {MainMenu} from "../../MainMenu";
import {Logout} from "../../PlattixUI/core/pages/Account/Logout";
import {RegisterPage} from "../../PlattixUI/core/pages/Account/Register";
import {ResetPasswordPage} from "../../PlattixUI/core/pages/Account/ResetPassword";
import {ForgotPasswordPage} from "../../PlattixUI/core/pages/Account/ForgotPasswordPage";
import {Reset2FAPage} from "../../PlattixUI/core/pages/Account/Reset2FA";
import {LoginPage} from "../../PlattixUI/core/pages/Account/Login";
import {ClickAwayListener, useMediaQuery} from "@mui/material";
import {ContentCardButtons, PlattixCard, PlattixCardContainer} from "../../PlattixUI/core/components/ContentCard";
import {deviceDimensions, ResponsiveComponent, ResponsiveSwipeDrawer} from "../../PlattixUI/core/components/Responsive";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {PlattixDataGrid} from "../../PlattixUI/core/grid/PlattixDataGrid";
import {GridSelectionModel} from "@mui/x-data-grid-pro";
import {SidebarItem} from "PlattixUI/core/Sidebar";
import {LanguageMenuComponent} from "../../PlattixUI/core/Partials/LanguageMenu";
import {CookieBannerComponent} from "../../PlattixUI/core/Partials/CookieBanner";

export function Landingpage() {
    const defaultTab = 'info';
    const [visibleTab, setVisibleTab] = useState(defaultTab);
    const userState = useSelector(userSelector);
    const currentSection = useRef(null);
    const history = useHistory();

    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);


    return (
        <>
            <LandingPageContainer>

                <LandingpageMenu/>
                <BodySection>

                    <HeadSection>
                        <ResponsiveComponent devices={['desktop']}>
                            <HeaderImage>
                                <img src={imgIndustrialArea} alt={'Industriepark Dendermonde in vogelvlucht.'} />
                            </HeaderImage>
                        </ResponsiveComponent>
                        {/*<Header>*/}
                        {/*    <h1>{t('My.Lovitas.Energy')}</h1>*/}
                        {/*    <p>{t('Lovitas.Description')}</p>*/}
                        {/*</Header>*/}
                        <Header>
                            <h1>{t('Lovitas')}</h1>
                            <p>Lovitas wilt bedrijven en particulieren ondersteunen bij het optimaliseren van hun
                                energiestromen
                                (productie en consumptie) mede door hernieuwbare energiegemeenschappen te helpen
                                opzetten.
                                In een hernieuwbare energiegemeenschap (ofwel REC – renewable energy community) werken
                                verschillende
                                partijen samen om energie op te wekken via hernieuwbare energiebronnen en nadien
                                onderling te
                                verdelen of op te slaan in de vorm van een batterij.</p>
                        </Header>
                    </HeadSection>

                    <SectionSpacer/>

                    <ColumnGroups>

                        {/*<Section>*/}
                        {/*    <TextArea*/}
                        {/*        title={t('AIDrivenEnergy.Title')}*/}
                        {/*        icon={faBrain}*/}
                        {/*        description={t('AIDrivenEnergy.Description')}*/}
                        {/*    />*/}
                        {/*    <TextArea*/}
                        {/*        title={t('EnergyAsAService.Title')}*/}
                        {/*        icon={faBolt}*/}
                        {/*        description={t('EnergyAsAService.Description')}*/}
                        {/*    />*/}
                        {/*    <TextArea*/}
                        {/*        title={t('FiberAsAService.Title')}*/}
                        {/*        icon={faEthernet}*/}
                        {/*        description={t('FiberAsAService.Description')}*/}
                        {/*    />*/}
                        {/*    <TextArea*/}
                        {/*        title={t('ChargeItAsAService.Title')}*/}
                        {/*        icon={faPlugCircleBolt}*/}
                        {/*        description={t('ChargeItAsAService.Description')}*/}
                        {/*    />*/}
                        {/*    <TextArea*/}
                        {/*        title={t('HeatAsAService.Title')}*/}
                        {/*        icon={faFire}*/}
                        {/*        description={t('HeatAsAService.Description')}*/}
                        {/*    />*/}
                        {/*    <TextArea*/}
                        {/*        title={t('SolarAsAService.Title')}*/}
                        {/*        icon={faSun}*/}
                        {/*        description={t('SolarAsAService.Description')}*/}
                        {/*    />*/}
                        {/*</Section>*/}

                        <Section>
                            <TextArea
                                title={t('AI gedreven energie')}
                                icon={faBrain}
                                description={'Analyseer met behulp van artificiële intelligentie de productie of consumptie van uw energieprofiel. Ontdek hoe u zowel thuis als binnen uw bedrijf de aangesloten apparaten en andere assets kunt beheren om uw energie efficiënt te beheren en uw kosten laag te houden.'}
                            />
                            <TextArea
                                title={t('Energie als een dienst')}
                                icon={faBolt}
                                description={'Deel samen met uw buren of omliggende bedrijven de opgewekte energie d.m.v. een energiegemeenschap. Beheer de administratie en analyseer de productie en/of de consumptie m.b.v. onze tools. Stuur daarnaast ook de aangsloten assets aan om de beschikbare energie zo efficiënt mogelijk te benutten en het verbruik van het gezamenlijke energieprofiel zo laag mogelijk te houden.'}
                            />
                            <TextArea
                                title={t('Fiber als een dienst')}
                                icon={faEthernet}
                                description={'Upgrade uw internetverbinding met een aansluiting op het web via een glasvezelkabel. Hiermee kan data met een enorme snelheid worden opgehaald en verzonden. Daarnaast geniet u van een stabiele verbinding, zelfs als u data deelt over grote afstanden.'}
                            />
                            <TextArea
                                title={t('Charge-it als een dienst')}
                                icon={faPlugCircleBolt}
                                description={'Beheer zorgenloos uw laadparkings met behulp van ons platform. Krijg gedetailleerd inzicht met behulp van onze tools en ervaar een probleemloze integratie met ons ERP-systeem.'}
                            />
                            <TextArea
                                title={t('Heat als een dienst')}
                                icon={faFire}
                                description={'Laat opgewekte warmte niet verloren gaan, maar optimaliseer uw warmtebeheer a.d.h.v. een warmtenet. Water wordt opgewarmd met de al-opgewekte-warmte door bedrijven of particulieren en kan makkelijk verspreid worden via ondergrondse leidingen om warmte te bieden aan omwonenden of andere bedrijven.'}
                            />
                            <TextArea
                                title={t('Solar als een dienst')}
                                icon={faSun}
                                description={'Heeft u geen ruimte om zelf zonnepanelen te plaatsen, maar wilt u toch genieten van de voordelen hiervan? Of heeft u hier wel de ruimte voor maar niet de middelen? Door samen te werken kunnen we u koppelen aan een partner die voldoet aan uw behoeften en maken wij het jullie zo gemakkelijk mogelijk om de opgewekte energie te delen.'}
                            />

                        </Section>

                        <ResponsiveComponent devices={['tablet', 'mobile']}>
                            <SectionSpacer/>
                        </ResponsiveComponent>


                        <Section>
                            <TextArea
                                title={t('Ontstaan')}
                                icon={faLightbulb}
                                description={'Sinds 2016 leeft in Dendermonde de wens om een hechte samenwerking op energetisch niveau te creëren ' +
                                    'tussen de bedrijven om hun CO2-neutraliteit gezamenlijk te maximaliseren. Lovitas bv is hieruit ' +
                                    'ontstaan in 2019 als een spin-off van de Business Club en is de allereerste lokale hernieuwbare ' +
                                    'energiegemeenschap in Dendermonde. Intussen zijn er al meer dan 40 bedrijven lid geworden en hebben ' +
                                    'we een aanvraag ingediend om erkend te worden als regelluwe zone.'}
                            />
                            <TextArea
                                title={t('Doel')}
                                icon={faBullseye}
                                description={'Door zelf een eigen energiegemeenschap opgericht te hebben, beseffen we hoeveel administratie, tijd ' +
                                    'en geld hierin kruipt. Het is dan ook ons doel om een facilitator te zijn voor ieder bedrijf/persoon ' +
                                    'die een hernieuwbare energiegemeenschap wilt opzetten. In plaats van met verschillende actoren in ' +
                                    'contact te staan wensen wij uw enige contactpersoon te zijn tijdens het aanmaken van zo’n REC. Wij ' +
                                    'zullen instaan voor de communicatie, van studie tot voltooiing, voor uw energiegemeenschap.'}
                            />
                            <TextArea
                                title={t('Doelgroep')}
                                icon={faUsers}
                                description={'Voor iedereen die de transitie van fossiele brandstoffen naar hernieuwbare bronnen een handje wilt ' +
                                    'helpen. Voor de bedrijven die hun ecologische voetafdruk aanzienlijk willen verkleinen. Voor de ' +
                                    'particulieren die samen met hun buren een aparte micro grid willen opstarten en samen besparen op ' +
                                    'energie.'}
                            />
                            <TextArea
                                title={t('Voordelen')}
                                icon={faClipboardCheck}
                                description={'1 Single point of contact (SPOC). ' +
                                    '1 Uniek aanspreekpunt waarbij u terecht kan met vragen, opmerkingen en die u op een transparante ' +
                                    'manier op de hoogte houdt van alle stappen.'}
                            />
                            <TextArea
                                title={t('Besparing')}
                                icon={faPiggyBank}
                                description={'U bespaart zowel tijd als geld als u Lovitas inschakelt. Wij kennen nl. de partijen die nodig zijn ' +
                                    'voor een hernieuwbare energiegemeenschap. Bovendien werken wij nauw samen met onze partner, ' +
                                    'Energytix, voor een betrouwbaar, transparant en innovatief energiemanagementsysteem.'}
                            />
                            <TextArea
                                title={t('Samen')}
                                icon={faUserGroup}
                                description={'Door samen te werken met de mensen in onze directe omgeving wordt het mogelijk om samen energie op te ' +
                                    'wekken en onderling te delen. Zo dragen ook wij ons steentje bij naar een groenere toekomst.'}
                            />
                            {/*</ColumnGroup>*/}

                        </Section>

                    </ColumnGroups>
                </BodySection>

                
                <ResponsiveComponent devices={['desktop']}>
                    <BackgroundMenu/>
                </ResponsiveComponent>

                <BackgroundDetails>
                    <BDetailHorizontal>
                        <BDetail className={'top'}>
                            <img src={bochtGroen} alt="Groene bocht"/>
                        </BDetail>
                        <BDetail className={'bottom'}>
                            <img src={bochtGrijs} alt="Grijze bocht"/>
                        </BDetail>
                    </BDetailHorizontal>
                    <BDetailVertical>
                        <BDetail className={'right'}>
                            <img src={bochtGeel} alt="Gele bocht"/>
                        </BDetail>
                    </BDetailVertical>
                </BackgroundDetails>
            </LandingPageContainer>

            <LiveChatWidget license="13878375" group="0"/>
        </>
    );
}

export function LandingpageMenu() {
    const userState = useSelector(userSelector);
    const [openLoginSection, setOpenLoginSection] = useState(false);
    const [openDrawer, setOpenDrawer] = useState({
        login: false,
    });

    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);

    const openLoginHandler = () => {
        setOpenLoginSection(!openLoginSection);
    }

    const toggleDrawer = (anchor: string, open: boolean) => {
        setOpenDrawer({...openDrawer, [anchor]: open});

        // return (event: React.KeyboardEvent | React.MouseEvent) => {
        //     if (
        //         event &&
        //         event.type === 'keydown' &&
        //         ((event as React.KeyboardEvent).key === 'Tab' ||
        //             (event as React.KeyboardEvent).key === 'Shift')
        //     ) {
        //         return;
        //     }
        //
        //     setOpenDrawer({ ...openDrawer, [anchor]: open })
        // };
    };

    useEffect(() => {
        if (userState.userStatus.isLoggedIn) setOpenLoginSection(false);
    }, [userState.userStatus.isLoggedIn]);

    return (<>
        <MenuSection>
            <LandingPageLogo>
                <img src={logo} alt="Logo Lovitas"/>
            </LandingPageLogo>

            {(!userState.userStatus.isLoggedIn) ?
                <>
                    <ResponsiveComponent devices={['mobile', 'tablet']}>
                        <ConfirmButton onClick={() => toggleDrawer('login', true)}>
                            <FontAwesomeIcon icon={openLoginSection ? faTimes : faRightFromBracket}/>
                            {t('Login')}
                        </ConfirmButton>
                    </ResponsiveComponent>
                    <ResponsiveComponent devices={['desktop']}>
                        <LoginSlider userState={userState}/>
                    </ResponsiveComponent>
                </>
                :
                <>
                    <AccountInformation/>
                    <LogoutButton>{t('Logout')}</LogoutButton>
                </>
            }

            {(!userState.userStatus.isLoggedIn) &&
                openLoginSection &&
                <LoginSection open={openLoginSection}>
                    <FontAwesomeIcon icon={faTimes} onClick={openLoginHandler} className={closeButton}/>
                    <LoginComponent userState={userState}/>
                </LoginSection>

            }

            <ResponsiveComponent devices={['desktop']}>
                <SidebarComponentsContainer>
                    <section className="sidebar">
                        {/*<SidebarItem*/}
                        {/*    name={'cookies'}*/}
                        {/*    icon={'cookie-bite'}*/}
                        {/*    explanation={t('Cookies')}*/}
                        {/*    explanationPos={'bottom'}*/}
                        {/*    circle={true}*/}
                        {/*    needsConfirmation={true}*/}
                        {/*    onClick={() => toggleDrawer('cookies', true)}*/}
                        {/*    hideOptions={(mobile || tablet)}*/}
                        {/*    optionsPos={'top'}*/}
                        {/*>*/}
                        {/*    <ResponsiveComponent devices={['desktop']}>*/}
                        {/*        <CookieBannerComponent/>*/}
                        {/*    </ResponsiveComponent>*/}

                        {/*    <ResponsiveSwipeDrawer*/}
                        {/*        devices={['mobile', 'tablet']}*/}
                        {/*        title={t('Cookies.WeUseCookies')}*/}
                        {/*        open={openDrawer['cookies']}*/}
                        {/*        onClose={() => toggleDrawer('cookies', false)}*/}
                        {/*        onOpen={() => toggleDrawer('cookies', true)}*/}
                        {/*    >*/}
                        {/*        <CookieBannerComponent/>*/}
                        {/*    </ResponsiveSwipeDrawer>*/}
                        {/*</SidebarItem>*/}

                        {/* Tijdelijk uitschakelen voor de demo */}

                        {/*<SidebarItem*/}
                        {/*    name={'language'}*/}
                        {/*    icon={'globe-europe'}*/}
                        {/*    explanation={t('Language')}*/}
                        {/*    explanationPos={'bottom'}*/}
                        {/*    circle={true}*/}
                        {/*    onClick={() => toggleDrawer('language', true)}*/}
                        {/*    hideOptions={(mobile || tablet)}*/}
                        {/*    optionsPos={'bottom'}*/}
                        {/*>*/}
                        {/*    <ResponsiveComponent devices={['desktop']}>*/}
                        {/*        <LanguageMenuComponent/>*/}
                        {/*    </ResponsiveComponent>*/}

                        {/*    <ResponsiveSwipeDrawer*/}
                        {/*        devices={['mobile', 'tablet']}*/}
                        {/*        title={t('Language')}*/}
                        {/*        open={openDrawer['language']}*/}
                        {/*        onClose={() => toggleDrawer('language', false)}*/}
                        {/*        onOpen={() => toggleDrawer('language', true)}*/}
                        {/*    >*/}
                        {/*        <LanguageMenuComponent/>*/}
                        {/*    </ResponsiveSwipeDrawer>*/}
                        {/*</SidebarItem>*/}

                        {/* Deze pagina's bestaan nog niet */}

                        {/*<SidebarItem*/}
                        {/*    name={'help'}*/}
                        {/*    icon={'question'}*/}
                        {/*    explanation={t('Plattix.HelpCenter')}*/}
                        {/*    explanationPos={'bottom'}*/}
                        {/*    circle={true}*/}
                        {/*    onClick={() => toggleDrawer('help', true)}*/}
                        {/*    hideOptions={(mobile || tablet)}*/}
                        {/*    optionsPos={'right'}*/}
                        {/*>*/}
                        {/*    <ResponsiveComponent devices={['desktop']}>*/}
                        {/*        <p className="option">{t('Plattix.HelpCenter')}</p>*/}
                        {/*        <Link className="option" to="/Legal/CompanyDetails">{t('Plattix.CompanyDetails')}</Link>*/}
                        {/*        <Link className="option" to="/Legal/TermsAndConditions">{t('Plattix.TermsAndConditions')}</Link>*/}
                        {/*        <Link className="option" to="/Legal/PrivacyPolicy">{t('Plattix.PrivacyPolicy')}</Link>*/}
                        {/*        <Link className="option" to="/Legal/CookiePolicy">{t('Plattix.CookiePolicy')}</Link>*/}
                        {/*    </ResponsiveComponent>*/}

                        {/*    <ResponsiveSwipeDrawer*/}
                        {/*        devices={['mobile', 'tablet']}*/}
                        {/*        title={t('Plattix.HelpCenter')}*/}
                        {/*        open={openDrawer['help']}*/}
                        {/*        onClose={() => toggleDrawer('help', false)}*/}
                        {/*        onOpen={() => toggleDrawer('help', true)}*/}
                        {/*    >*/}
                        {/*        <Link className="option" to="/Legal/CompanyDetails">{t('Plattix.CompanyDetails')}</Link>*/}
                        {/*        <Link className="option" to="/Legal/TermsAndConditions">{t('Plattix.TermsAndConditions')}</Link>*/}
                        {/*        <Link className="option" to="/Legal/PrivacyPolicy">{t('Plattix.PrivacyPolicy')}</Link>*/}
                        {/*        <Link className="option" to="/Legal/CookiePolicy">{t('Plattix.CookiePolicy')}</Link>*/}
                        {/*    </ResponsiveSwipeDrawer>*/}
                        {/*</SidebarItem>*/}
                    </section>
                </SidebarComponentsContainer>
            </ResponsiveComponent>
            
            


        </MenuSection>
        <ResponsiveComponent devices={['mobile', 'tablet']}>
            {/*<MobileBarMenu/>*/}
            <ResponsiveSwipeDrawer
                devices={['mobile', 'tablet']}
                title={''}
                open={openDrawer['login']}
                onClose={() => toggleDrawer('login', false)}
                onOpen={() => toggleDrawer('login', true)}
            >
                <LoginComponent userState={userState}/>

            </ResponsiveSwipeDrawer>
        </ResponsiveComponent>
    </>);
}

export interface LoginComponentProps {
    userState: UserState,
}

export function LoginComponent(props: LoginComponentProps) {
    return (
        <LoginFormContainer>
            <Switch>
                <Route path='/logout' component={Logout}/>
                <Route path='/register' component={RegisterPage}/>
                <Route path='/password-reset' component={ResetPasswordPage}/>
                <Route path='/forgot-password' component={ForgotPasswordPage}/>
                <Route path='/2fa-reset' component={Reset2FAPage}/>
                <Route path='/'>
                    <LoginPage userState={props.userState}/>
                </Route>
            </Switch>
        </LoginFormContainer>
    );
}

export function LoginSlider(props: LoginComponentProps) {
    return (
        <LoginSliderContainer open={false}>
            <LoginComponent userState={props.userState}/>
        </LoginSliderContainer>

    );
}

export function LandingpageLoggedIn() {

    const notifications = [
        {
            title: 'Nieuwe layout',
            description: 'Sinds 1 april 2022 heeft Energytix een nieuwe layout. U kunt dit bezoeken door op onderstaande knop te drukken.',
            btn: [
                {
                    title: t('Ga naar Energytix'),
                    url: 'https://energytix.cloud/',
                }
            ],
        },
    ];

    const appCards: ApplicationCardProps[] = [
        {
            title: 'Energytix',
            img: backgroundEnergytix,
            logo: logoEnergytix,
            description: t('Energytix is een webbased IT-platform, gedreven door A.I., waarmee u als eindgebruiker een beter inzicht kunt krijgen op uw energieprofiel enerzijds, en uw klimaatafdruk anderzijds. Op basis van de verworven informatie zal het Energytix platform u helpen om uw energieprofiel te optimaliseren, uw ecologische voetafdruk te minimaliseren en ten slotte de CO2-neutraliteit te maximaliseren'),
            btn: [
                {
                    title: t('Ga naar Energytix'),
                    url: 'https://energytix.cloud/',
                }
            ],
        },
        {
            title: 'Tactix',
            img: backgroundTactix,
            logo: logoTactix,
            btn: [
                {
                    title: t('Ga naar Tactix'),
                    url: 'https://tactix.cloud/',
                }
            ],
        }
    ];

    return (<>
        <LandingPageContainer>
            <LandingpageMenu/>
            <BodySection>
                <PlattixCardContainer>
                    {/*<ApplicationNotificationsCard title={t('Notifications')} notifications={notifications}/>*/}
                    <ApplicationNotificationsCard title={'Berichten'} notifications={notifications}/>

                    {appCards.map(ac => {
                        return <ApplicationCard
                            key={ac.title}
                            img={ac.img}
                            logo={ac.logo}
                            title={ac.title}
                            description={ac.description}
                            btn={ac.btn}
                        />
                    })}

                    <LandingPageInvoicesOutgoing/>
                    
                    <LandingPageInvoicesIncoming/>
                    
                </PlattixCardContainer>
            </BodySection>
        </LandingPageContainer>

        <BackgroundMenu/>

        <BackgroundDetails>
            <BDetailHorizontal>
                <BDetail className={'top'}>
                    <img src={bochtGroen} alt="Groene bocht"/>
                </BDetail>
                <BDetail className={'bottom'}>
                    <img src={bochtGrijs} alt="Grijze bocht"/>
                </BDetail>
            </BDetailHorizontal>
            <BDetailVertical>
                <BDetail className={'right'}>
                    <img src={bochtGeel} alt="Gele bocht"/>
                </BDetail>
            </BDetailVertical>
        </BackgroundDetails>
    </>);
}

export type ApplicationCardBtnType = {
    title: string,
    onClick?: () => void,
    url?: string,
}

export interface ApplicationCardProps {
    title: string,
    img: string,
    logo?: string,
    description?: string,
    btn: ApplicationCardBtnType[],
}

export function ApplicationCard(props: ApplicationCardProps) {
    const history = useHistory();

    const urlHandler = (e, url) => {
        console.log('url', url, e)
        e.stopPropagation();
        window.location.assign(url)
        // history.push(url);
    }

    return (<>
        <AppCard className={'module-container'}>
            <AppCardHeader>
                {props.logo &&
                    <img src={props.logo} alt={props.title} className={'logo'}/>
                }
                <img src={props.img} alt={props.title}/>
            </AppCardHeader>
            <AppCardBody>
                <h5>{props.title}</h5>
                {props.description &&
                    <p>{props.description}</p>
                }
            </AppCardBody>
            <ContentCardButtons className={AppCardButtons}>
                {props.btn.map(btn => {
                    if (btn.onClick) return <ConfirmButton key={btn.title} onClick={btn.onClick}>{btn.title}</ConfirmButton>
                    return <ConfirmButton key={btn.title} onClick={(e) => urlHandler(e, btn.url)}>{btn.title}</ConfirmButton>
                })}
            </ContentCardButtons>
        </AppCard>
    </>);
}

export type NotificationsType = {
    title: string,
    description: string,
    btn?: ApplicationCardBtnType[],
}

export interface ApplicationNotificationsCardProps {
    title: string,
    notifications?: NotificationsType[];
}

export function ApplicationNotificationsCard(props: ApplicationNotificationsCardProps) {

    const urlHandler = (e, url) => {
        console.log('url', url, e)
        e.stopPropagation();
        window.location.assign(url)
        // history.push(url);
    }

    return (<>
        <PlattixCard header={props.title} fullWidth={true}>
            {props.notifications ?
                <NotificationCardBody>
                    {props.notifications.map(note => {
                        return <NotificationItem key={note.title}>
                            <h5>{note.title}</h5>
                            <p>{note.description}</p>
                            <ContentCardButtons>
                                {note.btn?.map(btn => {
                                    if (btn.onClick) return <ConfirmButton key={btn.title}
                                                                           onClick={btn.onClick}>{btn.title}</ConfirmButton>
                                    return <ConfirmButton key={btn.title}
                                                          onClick={(e) => urlHandler(e, btn.url)}>{btn.title}</ConfirmButton>
                                })}
                            </ContentCardButtons>
                        </NotificationItem>
                    })}
                </NotificationCardBody>
                :
                <p>{t('Geen nieuwe berichten')}</p>
            }
        </PlattixCard>
    </>);
}

export interface TextAreaProps {
    description: string,
    title: string,
    icon: IconProp,
}

export function TextArea(props: TextAreaProps) {
    return (
        <TextSection>

            <ResponsiveComponent devices={['tablet', 'mobile', 'desktop']}>
                <HeaderText>
                    <FontAwesomeIcon icon={props.icon} className={'headerTextIcon'}/>
                    <h2>{props.title}</h2>
                </HeaderText>
            </ResponsiveComponent>
            {/*<ResponsiveComponent devices={['desktop']}>*/}
            {/*    <h2>{props.title}</h2>*/}
            {/*</ResponsiveComponent>*/}
            <p>{props.description}</p>

        </TextSection>
    );
}

export interface LandingPageInvoicesOutgoingProps {
}

export function LandingPageInvoicesOutgoing(props: LandingPageInvoicesOutgoingProps) {
    const [gridSelection, setGridSelection] = useState<GridSelectionModel | undefined>(undefined);

    // const rowOptions: GridRowOptions = [
    //     {
    //         label: t('Edit'),
    //         href: 'Edit',
    //     },
    //     {
    //         label: t('Details'),
    //         onClick: (id) => console.log(id),
    //     }
    // ]

    return (
        // <PlattixCard header={t('Invoices.Outgoing')} fullWidth={true}>
        <PlattixCard header={'Facturen'} fullWidth={true}>
            <PlattixDataGrid
                gridCode={'InvoiceOverviewGrid'}
                // rowOptions={rowOptions}
                showCheckbox={false}
                allowMultiSelect={true}
                onSelectionChanged={setGridSelection}
                selectionModel={gridSelection}
                includeLanguageCode
                // parameters={{'param_user_id': '123'}}
            />
        </PlattixCard>
    );
}

export interface LandingPageInvoicesIncomingProps {
}

export function LandingPageInvoicesIncoming(props: LandingPageInvoicesIncomingProps) {
    const [gridSelection, setGridSelection] = useState<GridSelectionModel | undefined>(undefined);
    const userState = useSelector(userSelector);
    console.log(userState)

    // const rowOptions: GridRowOptions = [
    //     {
    //         label: t('Edit'),
    //         href: 'Edit',
    //     },
    //     {
    //         label: t('Details'),
    //         onClick: (id) => console.log(id),
    //     }
    // ]

    return (
        // <PlattixCard header={t('Offers')} fullWidth={true}>
        <PlattixCard header={'Offertes'} fullWidth={true}>
            <PlattixDataGrid
                gridCode={'OffersAdmin'}
                // rowOptions={rowOptions}
                showCheckbox={false}
                allowMultiSelect={true}
                onSelectionChanged={setGridSelection}
                selectionModel={gridSelection}
                includeLanguageCode
                parameters={{'paramaccountid': '91'}}
            />
        </PlattixCard>
    );
}

function AccountInformation() {
    const userState = useSelector(userSelector);
    console.log(userState)
    
    if (!userState.user) return <></>;
    
    return (
        <AccountInfoContainer>
            <AccountInfoField>
                {/*<b>{t('Name')}</b>*/}
                <b>Naam</b>
                <p>{userState.user.firstName} {userState.user.LastName}</p>
            </AccountInfoField>
            <AccountInfoField>
                {/*<b>{t('Email')}</b>*/}
                <b>E-mail</b>
                <p>{userState.user.email}</p>
            </AccountInfoField>
        </AccountInfoContainer>
    );
}