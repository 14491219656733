import React, {useEffect} from "react";
import {useAppDispatch} from "../PlattixUI/PlattixReactCore/hooks";
import {useSelector} from "react-redux";
import {loginUser, userLoggedInSelector, userSelector} from "../PlattixUI/PlattixReactCore/UserSlice";
import {Route, Switch} from "react-router-dom";
import {Logout} from "../PlattixUI/core/pages/Account/Logout";
import {LoadingScreen} from "../PlattixUI/core/components/Loader";
import {Landingpage} from "../pages/Landingpage/Landingpage";

type Props = {
    noBackground?: boolean | undefined,
    noSidebarComponents?: boolean | undefined,
}

/**
 * Router that will check if user is logged in.
 * If not, it will redirect to the login page
 *
 * */

export function CheckLoginComponentLandingPage(props: React.PropsWithChildren<Props>) {
    const dispatch = useAppDispatch()

    const userState = useSelector(userSelector);
    const loggedIn = useSelector(userLoggedInSelector);
    const status = userState.userStatus;

    useEffect(() => {
        if (!status.isInitialised && !status.isLoading) {
            console.log("dispatching login")
            dispatch<any>(loginUser(null))
        }
    });

    if (loggedIn) return <>
        <Switch>
            <Route exact path="/logout"><Logout /></Route>
            <Route path="/">
                {props.children}
            </Route>
        </Switch>

    </>;

    if (!status.isInitialised) return <LoadingScreen />

    return <Landingpage/>

}