import {css, styled} from "goober";

const vars = {
    colors: {
        green: '#007566',
        yellow: '#fdd21c',
        grey: '#dddddd',
        red: '#ca4d35',
    },
    fontFamily: 'Aleo, serif',
    gap: '50px',
    documentPadding: '5vw',
    menuWidth: '300px',
    // menuWidth: '30%',
    backgroundBochten: '5vw',
    backgroundBochtenResp: '10vw',
};

export const BDetail = styled('div')`
    display: flex;
    
    &.top {
        justify-content: flex-end;
        align-items: flex-start;
        height: ${vars.backgroundBochten};
        max-width: 100%;
        
        @media only screen and (max-width: 1150px) {
            height: ${vars.backgroundBochtenResp};
        }
    }
    
    &.bottom {
        justify-content: flex-start;
        align-items: flex-end;
        height: ${vars.backgroundBochten};
        max-width: 100%;
        padding-left: 5vw;
        
        @media only screen and (max-width: 1150px) {
            height: ${vars.backgroundBochtenResp};
        }
    }
    
    &.right {
        justify-content: flex-end;
        align-items: flex-start;
        width: ${vars.backgroundBochten};
        max-height: 100%;
        
        @media only screen and (max-width: 1150px) {
            width: ${vars.backgroundBochtenResp};
        }
    }
    
    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

export const BDetailHorizontal = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    
    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

export const BDetailVertical = styled('div')`
    width: fit-content;
    height: 100%;
    display: flex;
    
    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

export const LandingPageContainer = styled('div')`
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-flow: row nowrap;
    padding: ${vars.documentPadding};
    padding-bottom: 60px;
    gap: calc(${vars.gap} * 2);
    font-family: 'Aleo', serif;
    overflow-y: auto;
    
    @media only screen and (max-width: 1150px) {
        flex-flow: column nowrap;
    }
`;

export const LandingPageLogo = styled('div')`
    width: 80%;
    
    img {
        max-width: 100%;
        max-height: 100%;
    }
    
    @media only screen and (max-width: 1150px) {
        width: 30%;
    }
`;

export const BackgroundDetails = styled('div')`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
`;

export const MenuSection = styled('div')`
    width: ${vars.menuWidth};
    display: flex;
    flex-flow: column nowrap;
    z-index: 2;
    gap: 50px;
    position: sticky;
    top: 0;
    
    @media only screen and (max-width: 1150px) {
        flex-flow: row nowrap;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
`;

export interface LoginSectionprops {
    open?: boolean,
}

export const LoginSection = styled('div')((props: LoginSectionprops) => {
    if (props.open) return `
        position: fixed;
        top: 0;
        right: 0;
        background: ${vars.colors.red};
        width: 50vw;
        height: 100vh;
        z-index: 1;
        padding: 5vw 50px;
        box-shadow: var(--shadow2);
        display: flex;
        flex-flow: column nowrap;
        gap: 25px;
        color: white;
        
        #content-container, .content-container-login {
            background: none;
            background-color: none;
            padding: 0;
            width: unset;
        }
        
        h2, h5, p, label, a {
            color: white !important;  
        }
            
        h2, h5 {
            font-weight: 700;
            font-family: ${vars.fontFamily};
            padding: var(--padding5);
            margin: 0;
            font-size: 3em;
        }
        
        .login-content-group {
            p {
                margin-bottom: 5px !important;
            }
        }
        
        .login-form {
            gap: ${vars.gap};
        }
        
        .login-logo {
            display: none;
        }
        
        .content-btn {
            border: 3px solid white;
            
            &, &:hover {
                box-shadow: none;
            }
        }
    `;
    
    return `
        width: fit-content;
        max-width: 250px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        z-index: 1;
    `;
});

export const LoginFormContainer = styled('div')`
    display: flex;
    flex-flow: column nowrap;
    gap: ${vars.gap};
    width: 100%;
    
    .login-content-group-container-bottom {
        display: flex;
        flex-flow: column nowrap;
    }
    
    .login-title {
        margin-bottom: 25px;
    }
`;

export const BodySection = styled('div')`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-flow: column nowrap;
    z-index: 1;
    gap: 50px;
    
    &::-webkit-scrollbar {
        display: none;
    }
    
    section {
        display: flex;
        flex-flow: column nowrap;
        gap: 100px;
    }
`;

export const Menu = styled('div')`
    width: 100%;
    display: flex;
    gap: 10px;
    flex-flow: column nowrap;
    font-size: 1.3em;
    
    .menu {
        overflow: unset;
    }
`;

export const Header = styled('div')`
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    bottom: 25px;
    left: 20%;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px;
    
    h1 {
        font-size: 5em;
        font-weight: 700;
        font-family: ${vars.fontFamily};
        width: clamp(300px, 70%, 1000px);
    }
    
    p {
        font-size: 1.5em;
        font-style: italic;
        font-family: ${vars.fontFamily};
        width: clamp(300px, 70%, 1000px);
    }
    
    @media only screen and (max-width: 1150px) {
        position: unset;
        background: unset;
        padding: unset;
        bottom: unset;
        left: unset;
        
        p {
            width: 100%;
        }
    }
`;

// export const HeadSection = styled('div')`
//     background: ${vars.colors.yellow};
// `;

export const HeadSection = styled('div')`
    position: relative;
`;

export interface TextSectionProps {
    left?: boolean,
    right?: boolean,
}

export const TextSection = styled('div')((props: TextSectionProps) => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: ${props.right ? 'flex-end' : 'flex-start'};
        gap: 20px;
        
        h2 {
            width: 100%;
            font-size: 3em;
            text-align: ${props.right ? 'right' : 'left'};
            font-family: ${vars.fontFamily};
        }
        
        p {
            width: 100%;
            font-family: ${vars.fontFamily};
            font-size: 1.3em;
            width: clamp(300px, 70%, 1000px);
            width: 100%;
            text-align: justify;
        }
        
        @media only screen and (max-width: 1150px) {
            gap: 20px;
    }
    `
});

export const HeaderText = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    
    .headerTextIcon {
        font-size: 3em;
    }
`;

export const ActionSection = styled('div')`
    border: 1px dotted red;
    width: 100%;
`;

export const SectionSpacer = styled('div')`
    border: var(--borderBottom1);
    width: 100%;
`;

export const Section = styled('section')`
    width: 100%;
`;

// export const GroupSection = styled('div')`
//     width: calc(100% + 5vw + 50px + 138px);
//     position: relative;
//     right: calc(5vw + 50px + 138px);
// `;

export const GroupSection = styled('div')`
    width: 100%;
    display: flex;
    gap: 50px;
    
    p {
        width: 100%;
    }
`;

export const closeButton = css`
    font-size: 20px;
    margin-left: auto;
    cursor: pointer;
`;

export const ColumnGroup = styled('div')`
    width: 100%;
    display: flex;
    gap: 50px;
    background: ${vars.colors.grey};
    background: #f5f5f5;
    padding: 25px;
    border-radius: 20px;
    
    h2 {
        text-align: center;
    }
    
    p {
        width: 100%;
    }
`;

export const ImageContainer = styled('div')((props) => {
    return `
        width: 100%;
        position: relative;
        
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            position: absolute;
        }
`});

export const TextContainer = styled('div')((props) => {
    return `
        width: 100%;
        height: fit-content;
        display: flex;
        flex-flow: column nowrap;
        gap: ${vars.gap};
        
        p {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
`});

export const BackgroundMenu = styled('div')((props) => {
    return `
        position: fixed;
        background: #f5f5f5;
        top: 0;
        left: 0;
        height: 100vh;
        width: calc(${vars.documentPadding} + ${vars.menuWidth} + ${vars.gap} + 10vw);
        width: calc(${vars.documentPadding} + ${vars.menuWidth} + (${vars.gap} / 2));
`});

export const ColumnGroups = styled('div')((props) => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        gap: ${vars.gap};
        
        @media only screen and (max-width: 1150px) {
            flex-flow: column nowrap;
        }
`});


export const AccordionSection = styled('div')((props) => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 25px;
`});

export const accordionStyle = {
    background: 'none',
    borderRadius: 0,
    margin: 0,
    boxShadow: 'none',
    'h2, p': {
        fontFamily: vars.fontFamily,
        margin: 0,
    },
    'h2': {
        fontFamily: vars.fontFamily,
    },
    '.MuiAccordionSummary-content': {
        'align-items': 'center',
        'justify-content': 'space-between',
        gap: '10px',
    },
};

export const accordionSummaryStyle = {
    background: 'none',
    borderRadius: 'unset',
    margin: 0,
    padding: 'unset',
    border: 'none',
    'flex-direction': 'row-reverse',
    gap: '10px',
};

export const accordionDetailsStyle = {
    background: 'none',
    borderRadius: 0,
    margin: 0,
    padding: '0',
};

export const AppCard = styled('div')((props) => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        overflow: hidden;
        height: auto;
`});

export const AppCardHeader = styled('div')((props) => {
    return `
        width: 100%;
        height: 100px;
        display: flex;
        background: #f5f5f5;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
        .logo {
            position: absolute;
            max-height: 50%;
            object-fit: contain;
            width: fit-content;
            bottom: 0;
            right: 0;
            padding: 10px;
            background: rgba(255, 255, 255, 0.7);
            border-top-left-radius: 10px;
        }
`});

export const AppCardBody = styled('div')((props) => {
    return `
        padding: var(--padding2);
        
        h3 {
            font-family: ${vars.fontFamily};
            font-size: 1.3em;
        }
`});

export const AppCardButtons = css`
    margin-top: auto;
`;

export const NotificationCardBody = styled('div')((props) => {
    return `
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        width: 100%;
        gap: 10px;
        
        @media only screen and (max-width: 1150px) {
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        }
`});

export const NotificationItem = styled('div')((props) => {
    return `
        width: 100%;
        border: 5px solid #f5f5f5;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
        
        h5, p {
            margin: 0;
        }
        
        .module-content-tab-btns {
            padding: 0;
        }
`});


export interface LoginSliderprops {
    open?: boolean,
}

export const LoginSliderContainer = styled('div')((props: LoginSliderprops) => {

    return `
        width: calc(${vars.menuWidth} + ${vars.documentPadding});
        width: calc(${vars.menuWidth} + ${vars.documentPadding} + (${vars.gap} / 2));
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        z-index: 1;
        background: ${vars.colors.grey};
        position: relative;
        left: -${vars.documentPadding};
        padding: 50px ${vars.gap} 50px calc(15px + ${vars.documentPadding});
        gap: 25px;
        
        #content-container, .content-container-login {
            background: none;
            background-color: none;
            padding: 0;
            width: unset;
        }
        
        h2, h5, p, label, a {
        }
            
        h2, h5 {
            font-weight: 700;
            font-family: ${vars.fontFamily};
            padding: var(--padding5);
            margin: 0;
            font-size: 3em;
        }
        
        .login-content-group {
            p {
                margin-bottom: 5px !important;
            }
        }
        
        .login-form {
            gap: ${vars.gap};
        }
        
        .login-logo {
            display: none;
        }
        
        .content-btn {
            
            &, &:hover {
                box-shadow: none;
            }
        }
    `;
});

export const SidebarComponentsContainer = styled('div')((props) => {

    return `
        width: 100%;
        display: flex;
        z-index: 1;
        
        .sidebar {
            height: unset;
            width: 100%;
            flex-flow: row wrap;
            justify-content: flex-start;
            padding: 0;
            background: none;
            
            .icon {
                &.circle-background {
                    background: ${vars.colors.red};
                    .link {
                        color: white;
                    }
                }
            }
        }
    `;
});

export const AccountInfoContainer = styled('div')((props) => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 20px;
    `;
});

export const AccountInfoField = styled('div')((props) => {
    return `
        width: 100%;
    `;
});


export const HeaderImage = styled('div')((props) => {
    return `
        width: clamp(250px, 40vw, 800px);
        max-height: 400px;
        background: ${vars.colors.red};
        overflow: hidden;
        
        img {
            max-width: 100%;
            max-height: 100%;
            opacity: 0.8;
        }
    `;
});
