import {ErrorType, HttpError} from "../CoreTypes";
import i18n, {t} from "../i18n";
import {ApiRootUrl} from "configuration/Configuration";
import {useQuery} from "react-query";

export type requestOptions = {
    abortSignal?: AbortSignal,
    /**
     * Send request to CORS server
     */
    remote?: boolean,
    /**
     * Throw exception on http error instead of returning HttpError
     */
    throwOnError?: boolean
}

export async function doPost<T>(url: string, data?: any, options?: requestOptions): Promise<T | HttpError> {
    try {

        if (!options?.remote)
            url = getFullUrl(url);


        // const response = await axios.post(url, data, config);
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(data),
            signal: options?.abortSignal
        })
        return await handleResponse(response, options);
    } catch (e: any) {
        if (options?.throwOnError) throw e;
        return handleError(e)
    }
}

export async function doPostFormData<T>(url: string, data?: FormData, options?: requestOptions): Promise<T | HttpError> {
    try {
        const response = await fetch(getFullUrl(url), {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: data,
            signal: options?.abortSignal
        })
        return await handleResponse(response, options);
    } catch (e: any) {
        return handleError(e)
    }
}


export function doPostFormDataXhr<T>(url: string, data?: FormData, onUploadProgress?: (number) => void, onDownloadProgress?: (number) => void): Promise<T | HttpError> {
    return new Promise((resolve, reject) => {
        try {

            const xhr = new XMLHttpRequest()

            xhr.upload.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    // console.log("upload progress:", event.loaded / event.total);
                    onUploadProgress?.(event.loaded / event.total)
                }
            });
            xhr.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    // console.log("download progress:", event.loaded / event.total);
                    // downloadProgress.value = event.loaded / event.total;
                    onDownloadProgress?.(event.loaded / event.total)
                }
            });

            xhr.onload = () => {
                const responseData = JSON.parse(xhr.responseText)
                if (xhr.status === 200) {
                    resolve(responseData as T)
                }

                resolve({
                    errorType: ErrorType.Server,
                    status: xhr.status,
                    statusText: xhr.statusText,
                    title: responseData?.title ?? GetMessageForStatusCode(xhr.status),
                    detail: responseData?.detail,
                    traceId: responseData?.traceId,
                    errors: responseData?.errors,
                } as HttpError)
            }

            xhr.open('Post', getFullUrl(url))
            xhr.withCredentials = true
            // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhr.send(data);

        } catch (e: any) {
            resolve(handleError(e))
        }
    });
}


const getSearchParams = (params?: GetParams) => {
    let searchParams = new URLSearchParams();
    if (params) {
        Object.keys(params)
            .forEach(k => {
                const value = params[k]
                if (value === null || value === undefined || params[k] === 0) return;

                if (Array.isArray(value)) {
                    value.forEach(v => searchParams.append(k, v))
                } else {
                    searchParams.append(k, value.toString())
                }
            })
    }
    return searchParams
}
export const getFullUrl = (url: string, searchParams?: URLSearchParams) => ApiRootUrl + url + (searchParams ? `?${searchParams?.toString()}` : '')

export type GetParams = { [key: string]: string | number | null | undefined | number[] | string[] }

export async function doGet<T>(url: string, params?: GetParams, options?: requestOptions): Promise<T | HttpError> {
    try {
        let searchParams = getSearchParams(params)

        if (!options?.remote)
            url = getFullUrl(url, searchParams)
        else
            url = url + (searchParams ? `?${searchParams?.toString()}` : '')

        const response: Response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            // cache: 'no-cache',
            // headers: {
            //     'cache-control': 'no-cache',
            // },
            credentials: 'include',
            signal: options?.abortSignal
        });

        return await handleResponse(response, options);
    } catch (e: any) {
        return handleError(e)
    }
}

export async function doDelete<T>(url: string, params?: GetParams, options?: requestOptions): Promise<T | HttpError> {
    try {
        let searchParams = getSearchParams(params)
        const response: Response = await fetch(getFullUrl(url, searchParams), {
            method: 'DELETE',
            mode: 'cors',
            // cache: 'no-cache',
            // headers: {
            //     'cache-control': 'no-cache',
            // },
            credentials: 'include',
            signal: options?.abortSignal
        });

        return await handleResponse(response, options);
    } catch (e: any) {
        return handleError(e)
    }
}

async function handleResponse<T>(response: Response, options: requestOptions | undefined) {
    let responseData;
    const contentType = response.headers.get("content-type");

    if (contentType && contentType.indexOf("application/json") !== -1) {
        try {
            responseData = await response.json();
        } catch {
            // ignore
        }
    }

    if (!response.ok) {
        if (options?.throwOnError) throw new Error(responseData?.title ?? GetMessageForStatusCode(response.status))
        return {
            errorType: ErrorType.Server,
            status: response.status,
            statusText: response.statusText,
            title: responseData?.title ?? GetMessageForStatusCode(response.status),
            detail: responseData?.detail,
            traceId: responseData?.traceId,
            errors: responseData?.errors,
        } as HttpError
    }

    return responseData as T;
}

function handleError(e: any): HttpError {
    if (e instanceof DOMException) {
        if (e.name === 'AbortError') {
            console.log('Fetch aborted');
            return RequestAbortedHttpError;
        }
    } else if (e instanceof TypeError) {
        if (e.message === "Failed to fetch") {
            return createFailedToFetchHttpError()
        }
    }


    console.log(e)
    return RequestFailedHttpError;
}

export async function throwOnHttpError<T>(response: Promise<T | HttpError> | T | HttpError) {
    const r = await response;
    if (isHttpError(r)) throw Error(r.title);
    return r;
}

export function isHttpError(response: HttpError | any): response is HttpError {
    if (response === undefined) return false;
    return (<HttpError>response).status !== undefined;
}

const RequestAbortedHttpError: HttpError = {
    errorType: ErrorType.Aborted, detail: "", errors: {}, status: 0, statusText: "", title: "", traceId: ""
}

const createFailedToFetchHttpError = (): HttpError => ({
    errorType: ErrorType.Network,
    detail: "",
    errors: {},
    status: 0,
    statusText: "Could Not Connect To Server",
    title: i18n.t("Error.FailedToContactServer"),
    traceId: ""
})

const RequestFailedHttpError: HttpError = {
    errorType: ErrorType.Unknown, detail: "", errors: {}, status: 0, statusText: "", title: "", traceId: ""
}

export function GetMessageForStatusCode(statusCode: number) {
    switch (statusCode) {
        // case 'Bad Request':
        case 400:
            return t("Response.BadRequest");
        // case 'Unauthorized':
        case 401:
            return t("Response.Unauthorized");
        // case 'Payment Required':
        case 402:
            return t("Response.PaymentRequired");
        // case 'Forbidden':
        case 403:
            return t("Response.Forbidden");
        // case 'Not Found':
        case 404:
            return t("Response.NotFound");
        case 405:
            return t("Response.MethodNotAllowed");
        case 406:
            return t("Response.NotAcceptable");
        case 407:
            return t("Response.ProxyAuthenticationRequired");
        // case 'Request Timeout':
        case 408:
            return t("Response.RequestTimeout");
        // case 'Conflict':
        case 409:
            return t("Response.Conflict");
        // case 'Gone':
        case 410:
            return t("Response.Gone");
        case 411:
            return t("Response.LengthRequired");
        // case 'Precondition Failed':
        case 412:
            return t("Response.PreconditionFailed");
        case 413:
            return t("Response.PayloadToLarge");
        case 414:
            return t("Response.UriTooLong");
        case 415:
            return t("Response.UnsupportedMediaType");
        case 416:
            return t("Response.RangeNotSatisfiable");
        case 417:
            return t("Response.ExpectationFailed");
        // case "I'm a teapot":
        case 418:
            return t("Response.ImATeapot");
        // case 'Internal Server Error':
        case 500:
            return t("Response.ServerError");
        default:
            return t("Error.Occured")
    }
}

export function useClientSettings() {
    return useQuery(["PlattixConfig"], async () => {
        return await doGet("/ClientSettings.json", undefined, {remote: true, throwOnError: true})
    }).data;
}

export function useHostUrl(hostname: string) {
    const appSettings: any = useClientSettings();
    return appSettings?.HostUrls.find(e => e.name === hostname).url;
}

