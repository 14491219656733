
export function formatEuro(number: number, decimals?: number | undefined) {
    return '€' + round(number, decimals).toLocaleString()
}

/**
 * Round a number to a certain number of decimal places. This function returns a number
 * 
 * For string formatting use {@see toFixedDecimal} 
 * @param number
 * @param decimals
 */
export function round(number: number, decimals?: number | undefined): number {
    decimals = decimals || 2;
    const power = (10 ** decimals )
    return Math.round((number + Number.EPSILON) * power) / power
}

/**
 * Format a number in the browsers locale with a fixed number of decimals
 * @param number - the number to format
 * @param decimals - number of decimal places (Default: 2)
 */
export function toFixedDecimal(number: number|null|undefined, decimals?: number | undefined): string {
    decimals = decimals ?? 2
    return number === null || number === undefined 
        ? "" : 
        // TODO: use locale of user
        number.toLocaleString(undefined, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        })
}