import {FieldPath, Path, PathValue, ValidateResult} from "react-hook-form";
import {t} from "../../PlattixReactCore/i18n";
import {PlattixValidatedInputProps} from "../components/form/Input";


export type ValidatorInput = number | string | undefined | null

export class Validator<TFieldValues> {

    protected get field() {
        return this.props?.name;
    }
    
    protected get form() {
        return this.props?.formHook;
    }
    
    public name = "Validator";
    
    private _props: PlattixValidatedInputProps<TFieldValues> | null = null;
    
    get props(): PlattixValidatedInputProps<TFieldValues> | null {
        return this._props;
    }

    set props(value: PlattixValidatedInputProps<TFieldValues> | null) {
        this._props = value;
    }
    
    constructor() {}
    
    public _register(props: PlattixValidatedInputProps<TFieldValues>){
        this.props = props;
    }
    
    validate(value: PathValue<TFieldValues, Path<TFieldValues>>) : ValidateResult | Promise<ValidateResult> {
        return true;
    }
} 

class RequiredValidator<TFieldValues> extends Validator<TFieldValues> {
    private readonly allowZero: boolean;
    
    public name = "required"

    constructor(allowZero: boolean) {
        super();
        this.allowZero = allowZero;
    }
    
    validate(value: PathValue<TFieldValues, Path<TFieldValues>>): ValidateResult {
        if (!this.form || !this.field) throw "Form and/or field not set";

        if (!this.allowZero && (value === '0' || value === 0)) return false;
        
        return !!value;
    }
}

class SameAsValidator<TFieldValues> extends Validator<TFieldValues> {
    private readonly other: FieldPath<TFieldValues>;
    
    public name = 'sameas'
    
    constructor(other: FieldPath<TFieldValues>, otherLabel?: string) {
        super();
        this.other = other;
        this.name = t('Validation.Error.SameAs', {other: otherLabel ?? other})
    }
    
    validate(value: PathValue<TFieldValues, Path<TFieldValues>>):  ValidateResult | Promise<ValidateResult>  {
        if (!this.form || !this.field) throw "Form and/or field not set";
        
        const otherValue = this.form.getValues(this.other);
        
        return value === otherValue;
    }
}

class RequiredIfValidator<TFieldValues> extends Validator<TFieldValues> {
    private readonly other: FieldPath<TFieldValues>;
    private readonly allowZero: boolean;
    
    public name = 'requiredIf'
    
    constructor(other: FieldPath<TFieldValues>, allowZero: boolean, otherLabel?: string, errorMessage?: string) {
        super();
        this.other = other;
        this.allowZero = allowZero;
        this.name = errorMessage ?? t('Validation.Error.RequiredIf', {other: otherLabel ?? other})
    }
    
    validate(value: PathValue<TFieldValues, Path<TFieldValues>>):  ValidateResult | Promise<ValidateResult>  {
        if (!this.form || !this.field) throw "Form and/or field not set";
        
        const otherValue = this.form.getValues(this.other);
        
        if (!!otherValue){
            if (!this.allowZero && value === '0'|| value === 0) return false;
            return !!value
        }
        
        return true;
    }
}

class RangeValidator<TFieldValues> extends Validator<TFieldValues> {
    private readonly min: number;
    private readonly max: number;
    private readonly exclusive?: boolean;
    
    public name = 'range'
    
    constructor(min: number, max: number, exclusive?: boolean) {
        super();
        this.min = min;
        this.max = max;
        this.exclusive = exclusive;
        this.name = t('Validation.Error.Range', {min: min, max: max})
    }
    
    validate(value: PathValue<TFieldValues, Path<TFieldValues>>):  ValidateResult | Promise<ValidateResult>  {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");
        
        if (isNaN(Number(value))) return true;
        
        if (this.exclusive)
            return this.min < value && value < this.max;
        
        return this.min <= value && value <= this.max;
    }
}

class MinValidator<TFieldValues> extends Validator<TFieldValues> {
    private readonly min: number;
    private readonly exclusive?: boolean;
    
    public name = 'min'
    
    constructor(min: number, exclusive?: boolean) {
        super();
        this.min = min;
        this.exclusive = exclusive;
        this.name = t('Validation.Error.Min', {min: min})
    }
    
    validate(value: PathValue<TFieldValues, Path<TFieldValues>>):  ValidateResult | Promise<ValidateResult>  {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");
        
        if (isNaN(Number(value))) return true;
        
        if (this.exclusive) return value > this.min;
        
        return value >= this.min;
    }
}

class MaxValidator<TFieldValues> extends Validator<TFieldValues> {
    private readonly max: number;
    private readonly exclusive?: boolean;
    
    public name = 'max'
    
    constructor(max: number, exclusive?: boolean) {
        super();
        this.max = max;
        this.exclusive = exclusive;
        this.name = t('Validation.Error.Max', {max: max})
    }
    
    validate(value: PathValue<TFieldValues, Path<TFieldValues>>):  ValidateResult | Promise<ValidateResult>  {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");
        
        if (isNaN(Number(value))) return true;
        
        if (this.exclusive) return value < this.max;
        
        return value <= this.max;
    }
}


class EmailValidator<TFieldValues> extends Validator<TFieldValues> {
    
    constructor() {
        super();
        this.name = t('Validation.Error.Email')
    }
    
    validate(value: PathValue<TFieldValues, Path<TFieldValues>>):  ValidateResult | Promise<ValidateResult>  {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");
        
        return true;
    }
}

class EmailListValidator<TFieldValues> extends Validator<TFieldValues> {
    
    constructor() {
        super();
        this.name = t('Validation.Error.Email')
    }
    
    validate(value: PathValue<TFieldValues, Path<TFieldValues>>):  ValidateResult | Promise<ValidateResult>  {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");
        
        return true;
    }
}

export function Required<TFieldValues> (allowZero?: boolean) {return new RequiredValidator<TFieldValues>(allowZero ?? true)}
export function SameAs<TFieldValues> (otherField: FieldPath<TFieldValues>, otherLabel?: string) {return new SameAsValidator<TFieldValues>(otherField, otherLabel)}

/// Field is required only when another field has a truthy value
export function RequiredIf<TFieldValues> (otherField: FieldPath<TFieldValues>, options?: {otherLabel?: string, allowZero?: boolean, errorMessage?: string}) {
    return new RequiredIfValidator<TFieldValues>(otherField, options?.allowZero ?? true, options?.otherLabel, options?.errorMessage)
}
/// Numeric value must be larger or equal than min and smaller or equal than max
/// If the value is not a number, the field will be considered valid
export function Range<TFieldValues> (min: number, max: number, exclusive?: boolean) {return new RangeValidator<TFieldValues>(min, max, exclusive)}
/// Numeric value must be larger or equal than min
/// If the value is not a number, the field will be considered valid
export function Min<TFieldValues> (min: number, exclusive?: boolean) {return new MinValidator<TFieldValues>(min, exclusive)}
/// Numeric value must be smaller or equal than max
/// If the value is not a number, the field will be considered valid
export function Max<TFieldValues> (max: number, exclusive?: boolean) {return new MaxValidator<TFieldValues>(max, exclusive)}
/// Numeric value must be larger or equal than 0
/// If the value is not a number, the field will be considered valid
export function LargerThanZero<TFieldValues> (){return new MinValidator<TFieldValues>(0, true)}

/**
 * Check if string has structure of email
 */
export function Email<TFieldValues>(){return new EmailValidator<TFieldValues>()}

/**
 * Require a list of email addresses separated by newline, comma or semicolon
 */
export function EmailList<TFieldValues>(){return new EmailListValidator<TFieldValues>()}
