

export const BaseApiEndpoints = {
    Account: {
        Login: "/Account/Login",
        ReLogin: "/Account/ReLogin",
        Logout: "/Account/Logout",
        Register: "/Account/Register",
        Forgot2FA: "/Account/Forgot2FA",
        ResetPassword: "/Account/ResetPassword",
        ResendVerification: "/Account/ResendEmailVerification",
        ForgotPassword: "/Account/ForgotPassword",
        UpdateLanguage: "/Account/UpdateLanguage"
    },
    Feedback: "/Feedback/SendFeedbackMail",
    Roles: {
        Entity: '/Role/Entity'
    }
}