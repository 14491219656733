import {User, UserLoginModel, UserRegisterModel} from "../UserSlice";
import {doPost, isHttpError} from "./Api";
import {HttpError} from "../CoreTypes";
import {Language} from "PlattixUI/PlattixReactCore/types/Languages";
import {BaseApiEndpoints} from "PlattixUI/PlattixReactCore/api/BaseApiEndpointMap";

export async function doUserLogin(userDetails: UserLoginModel | null) : Promise<User|HttpError>{
    return userDetails 
        ? await doPost(BaseApiEndpoints.Account.Login, userDetails)
        : await doPost(BaseApiEndpoints.Account.ReLogin, userDetails);
}
export async function doUserLogout() : Promise<boolean|HttpError>{
    return await doPost(BaseApiEndpoints.Account.Logout);
}

export async function doUpdateUserLanguage(lang: Language)  : Promise<boolean|HttpError>{
    const response =  await doPost(BaseApiEndpoints.Account.UpdateLanguage, lang.code);
    if (!isHttpError(response)) return true;
    return response;
}

export type ForgotPasswordModel = {
    email: string,
    code: string,
    password: string,
    confirmPassword: string
}

export type ForgotEmailModel = {
    email: string
}

export async function request2faReset(model: ForgotPasswordModel) : Promise<boolean|HttpError> {
    return await doPost(BaseApiEndpoints.Account.Forgot2FA, model)
}

export async function doRegister(model: UserRegisterModel) : Promise<boolean|HttpError> {
    return await doPost(BaseApiEndpoints.Account.Register, model)
}

export type ResetEmailModel = {
    email: string
}

export type ResetPasswordModel = {
    code: string,
    email: string,
    password: string,
    confirmPassword: string
}

export async function doResetPassword(email: ResetEmailModel) : Promise<boolean|HttpError> {
    return await doPost(BaseApiEndpoints.Account.ResetPassword, email)
}
export async function doChangePassword(email: ResetPasswordModel) : Promise<boolean|HttpError> {
    return await doPost(BaseApiEndpoints.Account.ResetPassword, email)
}

export async function doForgotPassword(email: ResetEmailModel) : Promise<boolean|HttpError> {
    return await doPost(BaseApiEndpoints.Account.ForgotPassword, email)
}

export async function doResendVerification(email: ResetEmailModel) : Promise<boolean|HttpError> {
    return await doPost(BaseApiEndpoints.Account.ResendVerification, email)
}

