import PasswordStrengthBar, {PasswordStrengthBarProps} from "react-password-strength-bar";
import i18n from "../../../PlattixReactCore/i18n";
import React from "react";


export function PasswordStrengthIndicator(props: PasswordStrengthBarProps) {
    return <PasswordStrengthBar
        minLength={8}
        shortScoreWord={i18n.t('Validation.PasswordStrength.TooShort')}
        scoreWords={[
            i18n.t('Validation.PasswordStrength.VeryWeak'),
            i18n.t('Validation.PasswordStrength.Weak'),
            i18n.t('Validation.PasswordStrength.Okay'),
            i18n.t('Validation.PasswordStrength.Good'),
            i18n.t('Validation.PasswordStrength.Strong'),
        ]}
        {...props}
    />
}
