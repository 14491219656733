import {LoginForm} from '../../forms/account/LoginForm';
import {UserState} from "PlattixUI/PlattixReactCore/UserSlice";
import {useTranslation} from "PlattixUI/PlattixReactCore/i18n";


export function LoginPage(params: { userState: UserState }) {
    const { t } = useTranslation();

    return (
        <div className="login-form">
            <h5 className="login-title">{t('Login')}</h5>
            <LoginForm userState={params.userState} />
        </div>
    );
}