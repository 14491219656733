import {useTranslation} from "../../../PlattixReactCore/i18n";
import {useState} from "react";
import {isHttpError} from "../../../PlattixReactCore/api/Api";
import {Button} from "../../components/Buttons";
import {doResendVerification} from "../../../PlattixReactCore/api/AccountApi";
import {HttpError} from "../../../PlattixReactCore/CoreTypes";


interface ResendVerificationMailProps {
    email: string
}

export function ResendVerificationMail(props: ResendVerificationMailProps) {    
    const {t} = useTranslation();
    
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<HttpError|null>(null);
    const [success, setSuccess] = useState(false);
    
    async function resendInvitation(){
        setLoading(true);
        
        const response = await doResendVerification({email: props.email});
        
        if (isHttpError(response)){
            setError(response)
        } else {
            setSuccess(true);
        }
        
        setLoading(false);
    }
    
    if (success) return <p>{t('RegistrationSuccessful.ResendMail.Confirmation')}</p>;
    
    return <>
        <p className={"login-error"}>{t('RegistrationSuccessful.ResendMail.Title')}</p>
        <Button loading={isLoading} onClick={resendInvitation}>{t('RegistrationSuccessful.ResendMail.Btn')}</Button>
        {/*{*/}
        {/*    success && <p>{t('RegistrationSuccessful.ResendMail.Confirmation')}</p>*/}
        {/*}*/}
        {
            error && <p className="login-error">{error.detail ?? error.title}</p>
        }
    </>
}