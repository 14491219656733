import * as React from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {loginUser, UserLoginModel, UserState} from "../../../PlattixReactCore/UserSlice";
import {useAppDispatch} from "../../../PlattixReactCore/hooks";
import {useTranslation} from '../../../PlattixReactCore/i18n';
import {Link} from "react-router-dom";
import {PlattixCheckbox, PlattixInput, PlattixSubmitButton} from "../../components/form/Input";
import {PlattixForm} from "../../components/form/Form";
import {ResendVerificationMail} from "../../pages/Account/ResendVerificationMail";
import {ContentCardButtons} from "../../components/ContentCard";

export function LoginForm(params: { userState: UserState }) {
    const {t} = useTranslation(['translation']);
    const dispatch = useAppDispatch();
    const userState = params.userState;
    
    const {register, handleSubmit, formState: {errors}, watch} = useForm<UserLoginModel>({
        defaultValues: {
            has2FA: !!userState.userStatus.error?.errors?.["needs2fa"]
        }
    });
    
    const email = watch('email')
    
    const onSubmit: SubmitHandler<UserLoginModel> = async data => {
        await dispatch(loginUser(data));
    }
    
    function resend() {
        ResendVerificationMail({email: email})
    }
    
    const needs2FA = userState.userStatus.error?.errors?.["needs2fa"];
    const emailNotConfirmed = userState.userStatus.error?.errors?.["emailNotConfirmed"];

    return (

        <PlattixForm onSubmit={handleSubmit(onSubmit)} autoComplete={needs2FA ? "nope" : "on"}>
            <PlattixInput
                disabled={userState.userStatus.isLoading}
                type={"email"}
                name="Email"
                register={register("email", {required: true})}
                label={t("Email")}
                placeholder={t("EmailPH")}
                autoComplete={needs2FA ? "nope" : "on"}
                error={errors.email || userState.userStatus.error}
            />
            <PlattixInput
                disabled={userState.userStatus.isLoading}
                type={"password"}
                name="password"
                register={register("password", {required: true})}
                label={t("Password")}
                placeholder={t("PasswordPH")}
                autoComplete={needs2FA ? "nope" : "on"}
                error={errors.password || userState.userStatus.error}
            />

            {needs2FA &&
            <>
                <input type="hidden" {...register("has2FA")} />
                <PlattixInput
                    disabled={userState.userStatus.isLoading}
                    name="token"
                    register={register("token", {required: true})}
                    label={t("Token")}
                    placeholder={t("Token")}
                    error={errors.token || userState.userStatus.error}
                />
                <PlattixCheckbox
                    name={"rememberMe30Days"}
                    label={t("RememberMe30Days")}
                    placeholder={t("RememberMe30Days")}
                    register={register("rememberMe30Days", {required: false})}
                    error={errors.rememberMe30Days || userState.userStatus.error}
                />
            </>
            }
            
            {/*<ContentCardButtons>*/}
                <PlattixSubmitButton loading={userState.userStatus.isLoading} disabled={userState.userStatus.isLoading} name={"Login"}/>
            {/*</ContentCardButtons>*/}

            {
                !needs2FA && userState.userStatus.error &&
                <div className="login-content-group error-box">
                    { !emailNotConfirmed && <p className="login-error">{userState.userStatus.error.errors?.["_global"] || userState.userStatus.error.title}</p> }
                    { emailNotConfirmed && <ResendVerificationMail email={email} /> }
                </div>
            }

            <div className="login-content-group-container-bottom">
                <div className="login-content-group">
                    <p>{t('ForgotPassword?')}</p>
                    <Link to="/forgot-password">{t('Reset')}</Link>
                </div>

                <div className="login-content-group">
                    <p>{t('Forgot2FA')}</p>
                    <Link to="/2fa-reset">{t('Reset2FA')}</Link>
                </div>

                <div className="login-content-group">
                    <p>{t('NoAccount')}</p>
                    <Link to="/register">{t('Register')}</Link>
                </div>
            </div>

        </PlattixForm>
    );

}
