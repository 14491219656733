import {doGet, doPost, isHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {
    GridColumnConfig,
    GridConfig,
    ServerGridConfig,
    ServerSideDataModel,
    ServersideDataProps
} from "PlattixUI/core/grid/types/GridTypes";
import {GridColDef} from "@mui/x-data-grid-pro";
import {GetCellRenderer} from "./CellRenderers";
import {GridFilterOperator} from "@mui/x-data-grid/models/gridFilterOperator";
import {GridFilterItem} from "@mui/x-data-grid/models/gridFilterItem";
import {GridCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {DataGridFilterInput} from "./DataGridFilterInput";
import {GridFilterConfig, ServerGridFilterConfig} from "PlattixUI/core/grid/types/GridFilterTypes";

function GetValueGetter(col: GridColumnConfig) {
    switch (col.dataType) {
        case "date":
        case "dateTime":
            return ({value}) => value && new Date(value)
        case "number":
            return ({value}) => value && Number(value)
    }
}


export async function fetchGridFilterOperatorsConfig(): Promise<GridFilterConfig> {
    const data = await doGet<ServerGridFilterConfig>('/ServerSideDataTable/Filters')
    if (isHttpError(data)) {
        throw new Error(data.title)
    }
    
    const operators: { [key: string]: GridFilterOperator[] } = {}
    
    data.dataTypes.forEach(dt => {
        operators[dt] = data.operators.filter(op => op.dataTypes.includes(dt)).map(op => {

            const operator: GridFilterOperator = {
                label: op.name,
                value: `${op.id}`,
                getApplyFilterFn: (filterItem: GridFilterItem, _: GridColDef) => {
                    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                        return null;
                    }

                    return (_: GridCellParams): boolean => {
                        return true;
                        // return Number(params.value) >= Number(filterItem.value);
                    };
                },
                InputComponent: DataGridFilterInput,
                InputComponentProps: {
                    "type": dt,
                    "operator": op
                }
            }
            return operator
        });
    })
    

    return {
        dataTypes: data.dataTypes,
        operators: operators
    };
}

export async function fetchGridConfig(gridCode: string, filterConfig?: GridFilterConfig): Promise<GridConfig> {
    const data = await doGet<ServerGridConfig>('/ServerSideDataTable/Config/' + gridCode)
    if (isHttpError(data)) {
        throw new Error(data.title)
    }
    if (!filterConfig){
        throw new Error('no filter config')
    }

    return {
        ...data,
        columns: data.columns.map(col => {
            return {
                field: col.columnName,
                headerName: col.header,
                sortable: col.orderable,
                hide: !col.visible,
                hideable: col.visible,
                type: col.dataType,
                flex: !col.width ? 1 : undefined,
                width: col.width,
                filterable: col.searchable,
                renderCell: GetCellRenderer(col),
                valueGetter: GetValueGetter(col),
                
                filterOperators: filterConfig.operators[col.dataType]
            }
        })
    }
}


export async function fetchServersideData(props: ServersideDataProps): Promise<ServerSideDataModel> {

    const data = await doPost<ServerSideDataModel>('/ServerSideDataTable/GetServersideData', props)
    if (isHttpError(data)) {
        throw new Error(data.title)
    }

    // gridConfig.columns.forEach(col => {
    //     switch ()
    //     if (col.type === "date" )
    // } )
    //
    // data.data.forEach(row => {
    //     if (row.ty)
    // })

    return data;
}