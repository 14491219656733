import {
    DataGridPro as DataGrid,
    deDE,
    enUS,
    frFR,
    GridCallbackDetails,
    GridFilterModel,
    GridInputSelectionModel,
    GridSelectionModel,
    GridSortModel,
    nlNL
} from '@mui/x-data-grid-pro';
import {useQuery} from 'react-query'
import * as React from "react";
import {useState} from "react";
import {GridConfig, ServerSideDataModel, ServersideDataProps} from "PlattixUI/core/grid/types/GridTypes";
import {
    fetchGridConfig,
    fetchGridFilterOperatorsConfig,
    fetchServersideData
} from './gridApi';
import {useAppSelector} from "PlattixUI/PlattixReactCore/hooks";
import {userLangCodeSelector} from "PlattixUI/PlattixReactCore/UserSlice";
import {GridRowOptions} from "PlattixUI/core/grid/gridStyling/GridOptions";
import {GetRowOptions} from "PlattixUI/core/grid/CellRenderers";
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import useDebounce from "PlattixUI/util/useDebounce";
import {SearchToolbar} from "PlattixUI/core/grid/SearchToolbar";
import {PlattixCard, PlattixCardContainer} from "PlattixUI/core/components/ContentCard";
import {t} from "PlattixUI/PlattixReactCore/i18n"
import {GridFilterConfig} from "PlattixUI/core/grid/types/GridFilterTypes";
import {DataGridHeader, dataGridStyling} from "./gridStyling/GridStyling";

export type GridProps = {
    gridCode: string,
    parameters?: { [key: string]: string }
    rowOptions?: GridRowOptions,

    selectionModel?: GridInputSelectionModel,
    onSelectionChanged?: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void,
    allowMultiSelect?: boolean,
    showCheckbox?: boolean,

    /**
     * Include the language code as a parameter on SS Request
     */
    includeLanguageCode?: boolean,
}

export function PlattixDataGrid(props: GridProps) {
    const userLang = useAppSelector(userLangCodeSelector)
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [gridSortModel, setGridSortModel] = useState<GridSortModel>([]);

    const [useQuickSearch, setUseQuickSearch] = useState(true);
    const [advancedFilter, setAdvancedFilter] = useState<GridFilterModel>({items: []});
    const [advancedFilterJson, setAdvancedFilterJson] = useState("");
    const [quickSearchText, setQuickSearchText] = React.useState('');
    const [quickSearchRegex, setQuickSearchRegex] = React.useState(false);
    const [quickSearchCaseSensitive, setQuickSearchCaseSensitive] = React.useState(false);

    const debouncedQuickSearchText = useDebounce(quickSearchText, 250);
    const debouncedAdvancedFilterJson = useDebounce(advancedFilterJson, 250);

    const [gridParameters, setGridParameters] = useState<{ [key: string]: string }>(props.parameters ?? {})

    async function getGridConfig() {
        const response = await fetchGridConfig(props.gridCode, filterConfig.data)

        if (response.columns?.length && props.rowOptions && props.rowOptions?.length) {
            const actionColumn: GridActionsColDef = {
                field: 'actions',
                type: "actions",
                getActions: (cell) => GetRowOptions(props.rowOptions, cell),
            }

            response.columns.push(actionColumn)
        }
        
        response.columns.map((col) => {
            col.minWidth = 100;
        });

        return response;
    }

    const filterConfig = useQuery<GridFilterConfig>(
        ['gridFilterConfig', userLang],
        fetchGridFilterOperatorsConfig,
        {
            // staleTime
            initialData: undefined,
            refetchOnWindowFocus: false,
            staleTime: 30 * 60 * 100,
            cacheTime: 300 * 60 * 100,
        }
    );
    const configQuery = useQuery<GridConfig>(
        ['gridConfig', props.gridCode, filterConfig.data?.operators],
        getGridConfig,
        {
            // enabled: !!filterConfig.data?.operators?.length
            refetchOnWindowFocus: false,
            enabled: !!filterConfig.data?.dataTypes?.length,
            // staleTime: 300_000,
            // cacheTime: 30 * 60 * 1000
        }
    );
    
    function getServersideDataParameters(): ServersideDataProps {
        const params: ServersideDataProps = {
            gridCode: props.gridCode,
            page: page,
            pageSize: pageSize,
            sort: gridSortModel,
            parameters: gridParameters ?? {},

            useQuickSearch: useQuickSearch,
            quickSearch: {
                regex: quickSearchRegex,
                caseSensitive: quickSearchCaseSensitive,
                value: debouncedQuickSearchText
            },
            advancedSearch: {
                logic: advancedFilter.linkOperator ?? "or",
                criteria: advancedFilter.items.map(item => {
                    return {
                        value: Array.isArray(item.value) ? item.value : [item.value],
                        columnName: item.columnField,
                        condition: Number(item.operatorValue)
                    }
                })
            },
        }
        if (props.includeLanguageCode) {
            params.parameters['paramlanguagecode'] = userLang
        }

        return params
    }

    const dataQuery = useQuery<ServerSideDataModel>(
        [
            'gridData', props.gridCode,
            page, pageSize, gridSortModel,
            userLang, gridParameters,
            debouncedQuickSearchText, quickSearchRegex, quickSearchCaseSensitive,
            debouncedAdvancedFilterJson
        ],
        () => fetchServersideData(getServersideDataParameters()),
        {
            keepPreviousData: true,
            initialData: {
                data: [],
                recordsFiltered: pageSize,
                recordsTotal: pageSize
            },
            enabled: !!configQuery?.data?.columns?.length,
            // staleTime: 5_000,
            cacheTime: 30_000
        });
    
    return (<>
            <DataGrid
                onSelectionModelChange={props.onSelectionChanged}
                selectionModel={props.selectionModel}
                checkboxSelectionVisibleOnly={true}
                disableMultipleSelection={!props.allowMultiSelect}
                checkboxSelection={props.showCheckbox}

                autoHeight
                pagination
                page={page}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 20, 50, 100]}
                onPageChange={(page) => setPage(page)}
                onPageSizeChange={pageSize => setPageSize(pageSize)}
                paginationMode={"server"}
                rowCount={dataQuery.data?.recordsTotal ?? 100}

                filterMode={"server"}
                filterModel={advancedFilter}
                onFilterModelChange={(filter) => {
                    setAdvancedFilter(filter)
                    setAdvancedFilterJson(JSON.stringify(filter))
                }}

                sortingMode={"server"}
                sortModel={gridSortModel}
                onSortModelChange={(model) => setGridSortModel(model)}

                loading={configQuery.isLoading || dataQuery.isLoading || dataQuery.isFetching}

                rows={dataQuery.data?.data ?? []}
                columns={configQuery.data?.columns ?? []}
                // pinnedColumns={{right: ['actions']}}

                // headerClassName={DataGridHeader}

                components={{Toolbar: SearchToolbar}}
                componentsProps={{
                    toolbar: {
                        meta: {
                            columns: configQuery.data?.columns ?? []
                        },
                        useQuickSearch: useQuickSearch,
                        setUseQuickSearch: setUseQuickSearch,
                        quickSearch: {
                            hasQuickSearch: configQuery.data?.hasQuickSearch ?? false,
                            hasRegexQuickSearch: configQuery.data?.hasRegexQuickSearch ?? false,
                            hasCaseSensitiveQuickSearch: configQuery.data?.hasCaseSensitiveQuickSearch ?? false,

                            value: quickSearchText,
                            onChange: (event) => setQuickSearchText(event.target.value),
                            clearSearch: () => setQuickSearchText(''),
                            regex: quickSearchRegex,
                            toggleRegex: () => setQuickSearchRegex(!quickSearchRegex),
                            caseSensitive: quickSearchCaseSensitive,
                            toggleCaseSensitive: () => setQuickSearchCaseSensitive(!quickSearchCaseSensitive),
                        },
                        advancedSearch: {
                            filter: advancedFilter,
                            savedFilters: configQuery.data?.savedFilters,
                            setAdvancedFilter: setAdvancedFilter
                        }
                    },
                }}

                localeText={getGridLocaleText(userLang)}
                sx={dataGridStyling}
            />
        </>

    );
}

/**
 * Get the data grid translations for the language
 * @param language
 */
function getGridLocaleText(language: string) {
    let localization = enUS;
    switch (language.toLowerCase()) {
        case "nl":
            localization = nlNL;
            break;
        case "fr":
            localization = frFR;
            break;
        case "de":
            localization = deDE;
            break;
    }

    return localization.components.MuiDataGrid.defaultProps.localeText
}

export function TestDataGrid() {
    const [gridSelection, setGridSelection] = useState<GridSelectionModel | undefined>(undefined);

    const rowOptions: GridRowOptions = [
        {
            href: 'link',
            label: 'test link',
            showIf: cell => cell.id > 10
        },
        {
            onClick: (id) => console.log(id),
            label: 'test action'
        }
    ]
    return <>

        <PlattixCardContainer >

            <PlattixCard fullWidth header={t("Messages")}>
                <PlattixDataGrid
                    // includeLanguageCode
                    gridCode={'Messages'} rowOptions={rowOptions}
                    showCheckbox={false} allowMultiSelect={true}
                    onSelectionChanged={setGridSelection}
                    selectionModel={gridSelection}
                />
            </PlattixCard>

            <PlattixCard fullWidth header={t("Messages")}>

                <h1>Geselecteerde Messages</h1>
                {
                    gridSelection?.map(sel => <h2 key={sel}>Contract met id {sel}</h2>)
                }
            </PlattixCard>
        </PlattixCardContainer>
    </>
}
