import React from 'react';
import {styled} from "goober";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {EditButton} from "./Buttons";
import {Alert, AlertTitle} from "@mui/material";
import {AlertColor} from "@mui/material/Alert/Alert";

export function Header(props: React.PropsWithChildren<any>) {
    return (
        <section className="header">
            {props.children}
        </section>
    );
}

export function Actions(props: React.PropsWithChildren<any>) {
    return (
        <div className="actions">
            {props.children}
        </div>
    );
}

export function ActionsLeft(props: React.PropsWithChildren<any>) {
    return (
        <div className="left">
            {props.children}
        </div>
    );
}

export function ActionsRight(props: React.PropsWithChildren<any>) {
    return (
        <div className="right">
            {props.children}
        </div>
    );
}

export interface ActionsItemContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    description?: string;
}

export function ActionsItemContainer(props: React.PropsWithChildren<any>) {
    return (
        <div className="item-container">
            {props.description && <h5>{props.description}</h5>}
            <div>
                {props.children}
            </div>
        </div>
    );
}



export function MessageBar(props: React.PropsWithChildren<any>) {
    return (
        <section className="messages">
            <div className="actions">
                {props.children}
            </div>
        </section>
    );
}

interface PlattixAlertProps {
    title: React.ReactNode,
    description?: React.ReactNode,
    actionTitle?: string,
    onAction?: () => void
    type?: AlertColor,
    onClose?: (event: React.SyntheticEvent) => void
}
export function PlattixAlert(props: PlattixAlertProps){
    return <Alert 
        action={props.onAction ? <EditButton onClick={props.onAction}>{props.actionTitle}</EditButton> : undefined} 
        severity={props.type} 
        onClose={props.onClose}
    >
        <AlertTitle>{props.title}</AlertTitle>
        {props.description}
    </Alert>
}
export function ErrorAlert(props: PlattixAlertProps){
    return <PlattixAlert {...props} type={props.type ?? "error"} />
}
export function WarningAlert(props: PlattixAlertProps){
    return <PlattixAlert {...props} type={props.type ?? "warning"} />
}
export function SuccessAlert(props: PlattixAlertProps){
    return <PlattixAlert {...props} type={props.type ?? "success"} />
}

/// Deprecated: use MUI Alert instead
const Message = styled('div')`
    display: flex;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    
    & > * {
        margin: 0;
    }
    
    h5 {
        width: 100%;
    }
`

/// Deprecated: use MUI Alert instead
export const WarningMessage = styled(Message)`
    background-color: var(--styleColor3);
    display: flex;
    justify-content: space-between;
    align-items: center;
`

/// Deprecated: use MUI Alert instead
export const ErrorMessage = styled(Message)`
    background-color: var(--buttonColorRed);
    display: flex;
    justify-content: space-between;    
    align-items: center;
    
    h4 {
        color: var(--textColorLight);
    }
`

export function PlattixTabs(props: React.PropsWithChildren<any>) {
    return (
        <Tabs className="tabs">
            {props.children}
        </Tabs>
    );
}

export function PlattixTabList(props: React.PropsWithChildren<any>) {
    return (
        <TabList>
            {props.children}
        </TabList>
    );
}

export function PlattixTab(props: React.PropsWithChildren<any>) {
    return (
        <Tab {...props} className="tab">
            {props.children}
        </Tab>
    );
}

export function PlattixTabPanel(props: React.PropsWithChildren<any>) {
    return (
        <TabPanel {...props}>
            {props.children}
        </TabPanel>
    );
}