import * as React from 'react';
import {useState} from 'react';
import {PlattixInput, PlattixSubmitButton} from "../../components/form/Input";
import {useForm} from "react-hook-form";
import {UserState} from "../../../PlattixReactCore/UserSlice";
import {useAppDispatch} from "../../../PlattixReactCore/hooks";
import {useTranslation} from '../../../PlattixReactCore/i18n';
import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {QueryParamSelector} from "../../../PlattixReactCore/selectors";
import * as accountApi from "../../../PlattixReactCore/api/AccountApi";
import {CancelButton, ConfirmButton} from "../../components/Buttons";
import {isHttpError} from "../../../PlattixReactCore/api/Api";
import {HttpError} from "../../../PlattixReactCore/CoreTypes";
import {PasswordStrengthIndicator} from "../../components/form/Validation";
import {PlattixForm} from "../../components/form/Form";
import {ContentCardButtons} from "../../components/ContentCard";

export function ResetPasswordForm(params: {userState: UserState}) {
    const {t} = useTranslation(['translation']);
    const queryParams = useSelector(QueryParamSelector);
    const dispatch = useAppDispatch();
    const [success, setSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<HttpError | null>(null);
     const userState = params.userState;
     const hist = useHistory()
    
    const code=queryParams.get("code");
    const email=queryParams.get("email")

    const form= useForm<accountApi.ResetPasswordModel>({defaultValues: {
            code: code|| "",
            email: email || "",
        }});
    const {register, handleSubmit, watch, formState: {errors}} = form;

    async function onSubmit(value: accountApi.ResetPasswordModel) {
        console.log(hist)

        setSuccess(false);
        setLoading(true);

        const response = await accountApi.doChangePassword(value);

        if (isHttpError(response)) {
            setError(error)
        } else {
            setSuccess(true);
        }

        setLoading(false);
     }

    if (!code || !email) {
        return (
            <>
                <div className="login-form">
                    <h5 className="login-title">{t('ForgotH4')}</h5>
                    <p>{t('Password.Reset.IvalidCode')}</p>
                    <Link to={'/'}><CancelButton>{t('Link.To.Login')}</CancelButton></Link>
                </div>
            </>
        )
    }

    if (success){
        return (
            <>
                <div className="login-form">
                    <h5 className="login-title">{t('ResetConfirmationP')}</h5>
                    <Link to={'/'}><ConfirmButton>{t('Link.To.Login')}</ConfirmButton></Link>
                </div>
            </>
        )
    }

    return (

        <PlattixForm  onSubmit={handleSubmit(onSubmit)} >
            <PlattixInput
                type={"hidden"}
                name="Email"
                register={register("email")}
                placeholder={t('EmailPH')}
                label={t('EmailPH')}
            />
            <PlattixInput
                type={"hidden"}
                name="Code"
                register={register("email")}
                placeholder={t('Code')}
                label={t('Code')}     
            />
            <PlattixInput
                type={"password"}
                name="password"
                register={register("password", {required: true})}
                label={t("PasswordPH")}
                placeholder={t('PasswordPH')}
            />
            <PasswordStrengthIndicator password={form.watch("password")} />
            <PlattixInput
                type={"password"}
                name="confirmPassword"
                register={register("confirmPassword", {required: true})}
                placeholder={t('ConfirmPasswordPH')}
                label={t("ConfirmPasswordPH")}
            />
            <ContentCardButtons>
               <PlattixSubmitButton name={"Submit"}/>
            </ContentCardButtons>
            
            {/*{*/}
            {/*    userState.status.error?.errors?.["_global"] &&*/}
            {/*    <div className="login-content-group error-box">*/}
            {/*        <p className="login-error">{userState.status.error.errors?.["_global"]}</p>*/}
            {/*        /!*@if (ViewBag.ConfirmationLink != null)*!/*/}
            {/*        /!*<a id="linkResendMail" href="#" onClick={resendRegistration()}>@ApplicationModel.Translate("Herverstuur mail")</a>*!/*/}
            {/*        /!*<p className="login-error hide" id="resendConfirmationText">@Html.Translate("RegistrationSuccessful.ResendMail.Confirmation")</p>*!/*/}
            {/*    </div>*/}
            {/*}*/}
            
            {/*<div className="module-content-tab-btns-2">*/}
            {/*    {userState.status.isLoading && <Spinner/>}*/}
            {/*    <PlattixSubmitButton disabled={userState.status.isLoading} name={"Reset"}/>*/}
            {/*</div>*/}

        </PlattixForm>
    );

}
