import {FieldError} from "react-hook-form";
import {HttpError} from "../../../PlattixReactCore/CoreTypes";
import i18n from "../../../PlattixReactCore/i18n";
import {isHttpError} from "../../../PlattixReactCore/api/Api";


export function isFieldError(error: FieldError | any): error is FieldError {
    return (error as FieldError).type !== undefined;
}

export function getErrorMessage(fieldName: string, error: FieldError | string | string[] | HttpError | null | undefined | {message: string, ref: any}): string | null {
    if (!error) return null;

    if (isFieldError(error)) {
        switch (error.type) {
            case "required":
                return i18n.t('Validation.Error.Required');
            default:
                return error.type
        }
    }

    if (isHttpError(error)) {
        return getErrorMessage(fieldName, error.errors?.[fieldName]);
    }

    if (Array.isArray(error)) {
        return error.join(", ")
    }
    
    if (typeof error === 'string'){
        return error;
    }
    
    if (error.message) return error.message;

    return 'error';

}