import logo from 'images/branding/lovitas/logo/logo.svg';
import loginBackground from 'images/branding/lovitas/background/login.png';
import currentAppIcon from 'images/branding/lovitas/icon/icon.svg';
import {IPlattixConfiguration} from "../PlattixUI/PlattixReactCore/types/PlattixConfiguration";

export const ApiRootUrl = (process.env.NODE_ENV === 'production' ? '' : 'http://localhost:52000') + '/api';

export const PlattixConfiguration: IPlattixConfiguration = {
    platformName: 'MyLovitas',
    clientName: 'Lovitas',
    
    applicationAccess: [{name: 'Energytix', url: '/'}],

    appIcon: currentAppIcon,
    mainLogo: logo,
    
    loginScreen: {
        background: loginBackground,
    }
}