import React, {PropsWithChildren, useEffect} from "react";
import ReactDOM from "react-dom";
import {styled} from "goober";
import {CancelButton, ConfirmButton} from "./Buttons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useTranslation} from "../../PlattixReactCore/i18n";

const modalMountPoint =
    document.getElementById("modalRoot")

//region styles
const ModalTitle = styled('h5')`
  margin: 0;
  flex-grow: 1;
  text-align: center;
`
const defaultBorderRadius = "10px"
const ModalContent = styled('div')`
  width: fit-content;
  max-width: 80vw;
  height: fit-content;
  max-height: 80vh;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  border-radius: ${defaultBorderRadius};
  display: flex;
  flex-direction: column;
`

const ModalBody = styled('div')`
  padding: 15px;
  flex-grow: 1;
  overflowY: auto;
`

const ModalHeader = styled('div')`
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #eee;
  position: relative;
`

const ModalCLoseButton = styled('div')`
  width: fit-content;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    height: 1.4em;
  }
`

const ModalFooter = styled('div')`
  border-top: 1px solid #eee;
  padding: 15px;
  float: right;;
`

const ModalDiv = styled('div')`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  &.enter-done {
    opacity: 1;
    pointer-events: visible;
  }

  &.exit {
    opacity: 0;
  }

  &.enter-done ${ModalContent} {
    transform: translateY(0);
  }

  &.exit ${ModalContent}{
    transform: translateY(-200px);
  }
`

//endregion

export interface ModalBaseProps {
    show: boolean,
    onClose: () => void,
}

export interface ModalProps extends ModalBaseProps{
    title?: string,

    showConfirmButton?: boolean,
    confirmButtonText?: string,
    onConfirm?: () => void,

    showCancelButton?: boolean,
    cancelButtonText?: string,
    onCancel?: () => void,
    closeOnCancel?: boolean,

    loading?: boolean,
    closeOnOutsideClick?: boolean

    customButton?: JSX.Element | JSX.Element[]
}

export function InfoModal(props: PropsWithChildren<ModalProps>) {
    const {t} = useTranslation();
    return <Modal {...props} cancelButtonText={props.cancelButtonText ?? t('Close')}/>
}

InfoModal.defaultProps = {
    showConfirmButton: false,
}

const Modal = (props: PropsWithChildren<ModalProps>) => {
    const {t} = useTranslation();

    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);

    if (!modalMountPoint) {
        console.error("No Modal mount point provided");
        return <></>
    }

    function onCancel() {
        if (props.onCancel) props.onCancel();
        if (props.closeOnCancel) close();
    }

    function close() {
        if (props.loading) return;

        props.onClose()
    }

    const confirmButtonText = props.confirmButtonText ?? t('Ok');
    const cancelButtonText = props.cancelButtonText ?? t('Cancel');
    
    if (!props.show) return  <></>

    return ReactDOM.createPortal(
        // <CSSTransition
        //     in={props.show}
        //     unmountOnExit
        //     timeout={{enter: 0, exit: 300}}
        // >
            <ModalDiv onClick={(props.closeOnOutsideClick ? close : undefined)}>
                <ModalContent onClick={e => e.stopPropagation()}>
                    {
                        props.title &&
                        <ModalHeader>
                            <ModalTitle>{props.title}</ModalTitle>
                            <ModalCLoseButton onClick={props.onClose}>
                                <FontAwesomeIcon icon={"times"}/>
                            </ModalCLoseButton>
                        </ModalHeader>
                    }
                    <ModalBody>{props.children}</ModalBody>
                    <ModalFooter>
                        {props.showCancelButton && <CancelButton onClick={onCancel}>{cancelButtonText}</CancelButton>}
                        {!!props.customButton && props.customButton}
                        {props.showConfirmButton && <ConfirmButton loading={props.loading} onClick={props.onConfirm}>{confirmButtonText}</ConfirmButton>}
                    </ModalFooter>
                </ModalContent>
            </ModalDiv>
        /*</CSSTransition>*/, modalMountPoint
    );
};
Modal.defaultProps = {
    closeOnOutsideClick: true,
    showConfirmButton: true,
    showCancelButton: true,
    closeOnCancel: true
}


export default Modal;