import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {isHttpError} from "./api/Api";
import {doRegister, doUpdateUserLanguage, doUserLogin, doUserLogout} from "./api/AccountApi"
import {getI18n} from "react-i18next";
import {toast} from "react-toastify";
import i18n from "./i18n";
import {HttpError, InitialStoreStatus, StoreStatus} from "./CoreTypes";
import {Dutch, Language} from "PlattixUI/PlattixReactCore/types/Languages";
import * as moment from "moment";

export type Role = "Administrator" | "Developer" | "Tester";

export interface User {
    email: string,
    firstName: string,
    LastName: string,

    claims: string[],
    roles: Role[],
    
    language: string,
    locale: string
}

export interface UserStatus extends StoreStatus {
    isLoggedIn: boolean
}

const initialUserStatus: UserStatus = {
    ...InitialStoreStatus,
    isLoggedIn: false
}

export interface UserState {
    user: User | null,
    userStatus: UserStatus,
    language: string
}

const initialState: UserState = {
    user: null,
    userStatus: initialUserStatus,
    language: i18n.language
};

//#region LOGIN

export interface UserLoginModel {
    email: string,
    password: string,
    remainLoggedIn: boolean,
    has2FA: boolean,
    token: string,
    rememberMe30Days: boolean
}

export const loginUser = createAsyncThunk<User,
    UserLoginModel | null,
    {
        rejectValue: HttpError | null
    }>(
    'users/login',
    async (userDetails, {rejectWithValue}) => {
        const response = await doUserLogin(userDetails)
        if (isHttpError(response)) {
            return rejectWithValue(response);
        }
        return response as User;
    })

//#endregion

//#region LOGOUT

export const logoutUser = createAsyncThunk<boolean,
    undefined,
    {
        rejectValue: HttpError
    }>(
    'users/logout',
    async (_, {rejectWithValue, getState}) => {
        const state = getState() as RootState
        if (!state.user.userStatus.isLoggedIn){
            return true;
        }
        const response = await doUserLogout()
        if (isHttpError(response)) {
            return rejectWithValue(response);
        }
        return true;
    })


//#endregion

//#region LOGIN

export interface UserRegisterModel {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    confirmPassword: string
}

export const registerUser = createAsyncThunk<boolean,
    UserRegisterModel,
    {
        rejectValue: HttpError | null
    }>(
        'users/register',
        async (model, { rejectWithValue }) => {
            const response = await doRegister(model)
            if (isHttpError(response)) {
                return rejectWithValue(response);
            }
            return response;
        })

//#endregion

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        initialize(state){
            state.userStatus.isInitialised = true;
        }, changeLanguage(state, action: PayloadAction<Language>){
            state.language = action.payload.code;
            i18n.changeLanguage(action.payload.code);
            if (!state.user) return;
            state.user.language = action.payload.code;
            state.user.locale = action.payload.locale;
            
            // const notifyFail = () => {
            //     toast(i18n.t('Error.Language.UpdateFail'), {type: "error", })
            // }
            
            // doUpdateUserLanguage(action.payload)
            //     .then(response => {
            //         if (isHttpError(response)) notifyFail()
            //     })
            //     .catch(reason => notifyFail());
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.fulfilled, (state, {payload}) => {
                // axios.defaults.withCredentials = true;
                state.user = payload;
                state.userStatus.isLoading = false;
                state.userStatus.isLoggedIn = true;
                state.userStatus.isInitialised = true;
                state.userStatus.error = null;
                state.language = payload.language

                moment.locale(payload.locale)
                getI18n().changeLanguage(payload.language)
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.user = null;
                state.userStatus.isLoading = false;
                state.userStatus.isLoggedIn = false;
                state.userStatus.isInitialised = true;
                
                if (!action.meta.arg && action.payload?.status === 401){
                    // no arguments were passed to the login request, it is a ReLogin request
                    // If this request fails with 401 UnAuthorized, ignore it
                    return
                }
                
                if (action.payload && action.meta.arg) {
                    // if no arguments were passed to login, it is the relogin request 
                    state.userStatus.error = action.payload
                } else {
                    // state.error = action.error
                }
            })
            .addCase(loginUser.pending, (state, action) => {
                state.userStatus.isLoading = true;
            })
            .addCase(logoutUser.fulfilled, (state, {payload}) => {
                // axios.defaults.withCredentials = false;
                state.user = null;
                state.userStatus.isLoading = false;
                state.userStatus.isLoggedIn = false;
                state.userStatus.isInitialised = true;
                state.userStatus.error = null;
            })
            .addCase(logoutUser.rejected, (state, action) => {
                state.userStatus.isLoading = false;
                if (action.payload) {
                    // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
                    state.userStatus.error = action.payload
                } else {
                    // state.error = action.error
                }
            })
            .addCase(logoutUser.pending, (state, action) => {
                state.userStatus.isLoading = true;
            })
    },
});

export const userSelector = (state: RootState) => state.user;
export const userLoggedInSelector = (state: RootState) => !!state.user.user;
export const isInRoleSelector = (state: RootState) => ((role: Role) => !!state.user.user?.roles.includes(role));
export const hasPlatformPermissionSelector = (state: RootState) => ((permission: string) => !!state.user.user?.claims.map(c => c.toLowerCase()).includes(permission.toLowerCase()));
export const isAdminSelector = (state: RootState) => !!state.user.user?.roles.includes("Administrator");
export const localeSelector = (state: RootState) => state.user.user?.locale;
export const userLangCodeSelector = (state: RootState) => state.user.language ?? i18n.language ?? 'nl';

export default userSlice.reducer;
export const { initialize, changeLanguage } = userSlice.actions


