import React, {useState} from "react";
import {keyframes, styled} from "goober";
import {useTranslation} from "PlattixUI/PlattixReactCore/i18n";
import {filterProps} from "PlattixUI/util/ElementProperties";
import windMillLoader2 from 'PlattixUI/images/loader/windmillLoader2.svg'
import windMillLoader1 from 'PlattixUI/images/loader/windmillLoader1.svg'

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerDiv = styled('span')`
  border: 16px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: ${rotate} 2s cubic-bezier(0.87, 0, 0.13, 1) infinite;
  display: inline-block;
  
  &.small {
    border-width: .25em;
    width: 1em;
    height: 1em;
  }
  
  &.large {
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
  }
`

export interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
    size?: "small" | "large"
}

export function Spinner(props: SpinnerProps) {

    return <SpinnerDiv {...filterProps(props, ['size'])} className={props.size ?? "large"}/>
}

const WindMillBlades = styled('div')`
  transform: scale(-1);
  width: 100%;
  height: 100%;
  background-image: url('${windMillLoader2}');
  position: absolute;
  animation: spin 2s linear infinite;
  top: 0;
`

const WindMillTower = styled('div')`
  width: 100%;
  height: 100%;
  background-image: url(${windMillLoader1});
  top: 0;
  position: absolute;
`



export const Windmill2container = styled('div')`

`

export const Windmill2figure = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
    
    svg {
        width: 100%;
        height: 100%;
        vertical-align: bottom;
        overflow: visible;
    }
    #wieken {
        animation: spin 10s infinite linear;
        transform-origin: 61% 44%;
    }
`


export const Windmill2 = () => {

    return <Windmill2container>
        <Windmill2figure >
            <svg id="svg-windmolen" width="714px" height="797px" viewBox="0 0 714 1000" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
                <defs>
                    <linearGradient x1="50%" y1="0%" x2="69.9174638%" y2="130.77405%" id="linearGradient-1">
                        <stop stopColor="#FFFFFF" stopOpacity="0.5" offset="0%"/>
                        <stop stopColor="#000000" stopOpacity="0.5" offset="100%"/>
                    </linearGradient>
                    <polygon id="path-2"
                             points="19.0581604 -3.98605853e-13 19.0581604 560.061 37.2463295 560.061 26.5631604 -5.1159077e-13"/>
                    <rect id="path-3" x="0" y="0" width="871" height="871"/>
                    <mask id="mask-4" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0"
                          width="871" height="871" fill="white">
                        <use xlinkHref="#path-3"/>
                    </mask>
                    <path
                        d="M623.655752,352.597412 C515.710752,380.387412 430.630752,412.407412 433.635752,424.092412 L824.580752,323.447412 C821.580752,311.742412 731.620752,324.802412 623.655752,352.597412"
                        id="path-5"/>
                    <path
                        d="M373.63556,615.502064 C265.69056,643.292064 180.61056,675.312064 183.61556,686.997064 L574.56056,586.352064 C571.56056,574.647064 481.60056,587.707064 373.63556,615.502064"
                        id="path-6"/>
                    <path
                        d="M265.666238,303.7829 C157.721238,331.5729 72.6412376,363.5929 75.6462376,375.2779 L466.591238,274.6329 C463.591238,262.9279 373.631238,275.9879 265.666238,303.7829"
                        id="path-7"/>
                    <path
                        d="M470.835,435.405 C470.835,454.95 454.98,470.825 435.43,470.825 C415.865,470.825 400,454.95 400,435.405 C400,415.845 415.865,400 435.43,400 C454.98,400 470.835,415.845 470.835,435.405"
                        id="path-8"/>
                </defs>
                <g id="Slides-Timeline" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(25, 203)">
                    <g id="Group-23" transform="translate(-108.000000, -201.000000)">
                        <g id="pole" transform="translate(419.000000, 437.000000)">
                            <polygon id="Fill-1" fill="#eee"
                                     points="12.3348988 -5.68434189e-14 0 560.061 18.6584012 560.061 18.6598988 -5.68434189e-14"/>
                            <g id="Fill-2">
                                <use fill="#F6F6F6" xlinkHref="#path-2"/>
                                <use fillOpacity="0.600000024" fill="url(#linearGradient-1)"
                                     xlinkHref="#path-2"/>
                            </g>
                        </g>
                        <g id="wieken">
                            <use id="Rectangle-14" stroke="#979797" mask="url(#mask-4)" strokeWidth="2" fill="#D8D8D8"
                                 opacity="0" xlinkHref="#path-3"/>
                            <path
                                d="M825.844538,324.073519 L434.904538,424.718519 C437.914538,436.408519 527.864538,423.338519 635.839538,395.548519 C743.779538,367.763519 828.864538,335.743519 825.844538,324.073519"
                                id="Fill-3" fill="#eee"
                                transform="translate(630.413523, 376.537022) rotate(-4.000000) translate(-630.413523, -376.537022) "/>
                            <g id="Fill-6"
                               transform="translate(629.069645, 371.623854) rotate(-4.000000) translate(-629.069645, -371.623854) ">
                                <use fill="#F6F6F6" xlinkHref="#path-5"/>
                                <use fillOpacity="0.3" fill="url(#linearGradient-1)" xlinkHref="#path-5"/>
                            </g>
                            <path
                                d="M569.71572,580.264334 L178.77572,680.909334 C181.78572,692.599334 271.73572,679.529334 379.71072,651.739334 C487.65072,623.954334 572.73572,591.934334 569.71572,580.264334"
                                id="Fill-3" fill="#eee"
                                transform="translate(374.284705, 632.727837) rotate(122.000000) translate(-374.284705, -632.727837) "/>
                            <g id="Fill-6"
                               transform="translate(379.049453, 634.528505) rotate(122.000000) translate(-379.049453, -634.528505) ">
                                <use fill="#F6F6F6" xlinkHref="#path-6"/>
                                <use fillOpacity="0.3" fill="url(#linearGradient-1)" xlinkHref="#path-6"/>
                            </g>
                            <path
                                d="M78.7560719,367.015731 C81.7660719,378.705731 171.716072,365.635731 279.691072,337.845731 C387.631072,310.060731 472.716072,278.040731 469.696072,266.370731 L78.7560719,367.015731 Z"
                                id="Fill-3" fill="#eee"
                                transform="translate(274.265057, 318.834234) rotate(230.000000) translate(-274.265057, -318.834234) "/>
                            <g id="Fill-6"
                               transform="translate(271.080131, 322.809342) rotate(230.000000) translate(-271.080131, -322.809342) ">
                                <use fill="#F6F6F6" xlinkHref="#path-7"/>
                                <use fillOpacity="0.3" fill="url(#linearGradient-1)" xlinkHref="#path-7"/>
                            </g>
                        </g>
                        <g id="as">
                            <use fill="#eee" xlinkHref="#path-8"/>
                            <use fillOpacity="0.300000012" fill="url(#linearGradient-1)" xlinkHref="#path-8"/>
                        </g>
                        <path
                            d="M435.987915,460 C422.755287,460 412,449.239295 412,435.987909 C412,422.760705 422.755287,412 435.987915,412 C449.208459,412 460,422.760705 460,435.987909 C460,449.239295 449.208459,460 435.987915,460"
                            id="Fill-19" fill="#eee"/>
                    </g>
                </g>
            </svg>
        </Windmill2figure>
    </Windmill2container>
}


export const WindMill = () => {
    useState()

    return <div className={"WindmillContainer"}>
        <WindMillTower/>
        <WindMillBlades style={{animation: `spin 30s linear infinite`}}/>
    </div>
}

const LoadingScreenDiv = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    
    
    ${Windmill2container} {
        flex-grow: 1;
        height: 100px;
    }
    
    h2 {
        text-align: center;
    }
`

export function LoadingScreen() {
    const {t} = useTranslation();
    return <LoadingScreenDiv>
        {/*<WindMill/>*/}
        <Windmill2 />
        <h2>{t('Loading')}</h2>
    </LoadingScreenDiv>
}