import React, {useState} from 'react';
import {useTranslation} from '../../../PlattixReactCore/i18n';
import {SubmitHandler, useForm} from "react-hook-form";
import {UserRegisterModel} from "../../../PlattixReactCore/UserSlice";
import {isHttpError} from "../../../PlattixReactCore/api/Api";
import {PlattixInput, PlattixSubmitButton, PlattixValidatedInput} from "PlattixUI/core/components/form/Input";
import {Link} from "react-router-dom";
import {ContentCardButtons} from "PlattixUI/core/components/ContentCard";
import {CancelButton} from "PlattixUI/core/components/Buttons";
import {Required, SameAs} from "PlattixUI/core/forms/FormValidators";
import {doRegister} from "PlattixUI/PlattixReactCore/api/AccountApi";
import {ResendVerificationMail} from "./ResendVerificationMail";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {PasswordStrengthIndicator} from "PlattixUI/core/components/form/Validation";
import {PlattixForm} from "PlattixUI/core/components/form/Form";

export function RegisterPage() {
    const {t} = useTranslation();

    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<HttpError|null>(null);
    const [success, setSuccess] = useState(false);

    const form = useForm<UserRegisterModel>();
    const {register, handleSubmit, formState: {errors}, watch } = form;
    
    const onSubmit: SubmitHandler<UserRegisterModel> = async data => {
        setLoading(true);

        const response = await doRegister(data);

        setLoading(false);
        if (isHttpError(response)) {
            setError(response);            
        } else {
            setSuccess(true);
        }
    }

    //const onSubmit: SubmitHandler<UserRegisterModel> = async data => {
    //    const response = await dispatch<any>(registerUser(data)) as HttpError;
    //}
        
    if (success) {
        return (
            <div>
                <h5>{t('RegistrationSuccessful')}</h5>
                <p>{t('RegistrationSuccessfulMessage')}</p>
                <ResendVerificationMail email={watch('email')}/>
            </div>
        )
    }

    return (
        <div className="login-form">
            <h5 className="login-title">{t('RegisterH4')}</h5>
            <PlattixForm onSubmit={handleSubmit(onSubmit)}>
                <PlattixInput
                    disabled={isLoading}
                    register={register("firstName", { required: true })}
                    label={t('FirstName')}
                    error={errors.firstName || error}
                    placeholder={t('FirstNamePH')}
                />
                <PlattixInput
                    disabled={isLoading}
                    register={register("lastName", {required: true})}
                    label={t('LastName')}
                    error={errors.lastName || error}
                    placeholder={t('LastNamePH')}
                />
                <PlattixInput
                    disabled={isLoading}
                    type={"email"}
                    register={register("email", {required: true})}
                    label={t('Email')}
                    error={errors.email || error}
                    placeholder={t('EmailPH')}
                />
                <PlattixInput
                    disabled={isLoading}
                    type={"password"}
                    register={register("password", {required: true, minLength: 8})}
                    label={t("Password")}
                    error={errors.password || error}
                    placeholder={t("PasswordPH")}
                />

                <PasswordStrengthIndicator password={form.watch("password")} />

                <PlattixValidatedInput<UserRegisterModel>
                    disabled={isLoading}
                    type={"password"}
                    label={t("ConfirmPassword")}
                    placeholder={t("ConfirmPasswordPH")}
                    name={"confirmPassword"}
                    formHook={form}
                    validation={[Required(), SameAs("password", t("PasswordPH"))]}
                />

                <ContentCardButtons>
                    {!isLoading && <Link to={"/"}><CancelButton/></Link>}
                    {/*{isLoading && <Spinner/>}*/}
                    <PlattixSubmitButton loading={isLoading} disabled={isLoading} name={t('Register')}/>
                </ContentCardButtons>

                {
                    error && !error?.errors?.["_global"] && (<>
                        <div className="login-content-group error-box">
                            <p className="login-error">{error.title}</p>
                        </div>
                    </>)
                }

                {
                    error?.errors?.["_global"] &&
                    <div className="login-content-group error-box">
                        <p className="login-error">{error.errors?.["_global"]}</p>
                    </div>
                }


            </PlattixForm>
        </div>
    );
}