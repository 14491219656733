import React, {useEffect} from 'react';

import './PlattixUI/scss/Index.scss';
import {setup, styled} from "goober";
import {userSelector} from "./PlattixUI/PlattixReactCore/UserSlice";
import {useAppDispatch, useAppSelector} from "./PlattixUI/PlattixReactCore/hooks";

import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'

import * as moment from 'moment'
import 'moment/locale/nl-be'
import 'moment/locale/fr'
import 'moment/locale/en-in'
import 'moment/locale/de'
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useMediaQuery} from "@mui/material";
import {deviceDimensions} from "./PlattixUI/core/components/Responsive";
import {QueryClient, QueryClientProvider} from "react-query";
import {setLicenseKeys} from 'PlattixUI/core/License';
import {LandingpageLoggedIn} from "./pages/Landingpage/Landingpage";
import {useLocation} from "react-router-dom";
import './App.css';
import {CheckLoginComponentLandingPage} from "./configuration/CheckLoginComponentLandingPage";

// @ts-ignore
library.add(fas)

setup(React.createElement);

const ResponsiveChecker = styled("div")`
  background: rgba(255, 255, 255, 0.5);
  border: 2px solid red;
  position: fixed;
  top: 0;
  right: 0;
  padding: 1px 4px;
  display: flex;
  flex-flow: column wrap;
  opacity: 0.2;
  
  div {
    display: flex;
    gap: 10px;
  }
  
  .true {
    color: green;
    font-weight: 700;
  }
  
  .false {
    color: red;
  }
`;
const queryClient = new QueryClient()
function App() {
    
    const {user, userStatus} = useAppSelector(userSelector)
    const dispatch = useAppDispatch();

    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);
    
    const debugResponsive = false;

    // license keys componenten ophalen
    setLicenseKeys();

    useEffect(() => {
        if (user) {
            moment.locale(user.locale)
        }
    }, [user])

    const location = useLocation();

    // if (location.pathname.split('/').includes('lovitas')) return <Landingpage/>;

    return (
        <QueryClientProvider client={queryClient}>
        <div id="root">

            <CheckLoginComponentLandingPage>
                <LandingpageLoggedIn/>

                {/*<ResponsiveComponent devices={['desktop']}>*/}
                {/*    <NavigationComponent/>*/}
                {/*    <SidebarComponent/>*/}
                {/*</ResponsiveComponent>*/}

                {/*<ResponsiveComponent devices={['mobile', 'tablet']}>*/}
                {/*    <MobileBarMenu/>*/}
                {/*</ResponsiveComponent>*/}
            </CheckLoginComponentLandingPage>
            <ToastContainer />
            
            {debugResponsive &&
                <ResponsiveChecker>
                    <div><b>Mobile: </b><p className={mobile ? 'true' : 'false'}>{`${mobile}`}</p></div>
                    <div><b>Tablet: </b><p className={tablet ? 'true' : 'false'}>{`${tablet}`}</p></div>
                    <div><b>Desktop: </b><p className={desktop ? 'true' : 'false'}>{`${desktop}`}</p></div>
                </ResponsiveChecker>
            }
            
        </div>
        </QueryClientProvider>
    );
}


export default App;
