import React, {PropsWithChildren, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {moduleColapse, netwerkTab, sideBarHover, sideBarHoverOut} from '../../js/newLayout/modules';
import {filterProps} from '../../util/ElementProperties';
import {IconLookup, IconName, IconPrefix} from '@fortawesome/fontawesome-svg-core';
import Modal from "./Modal";
import {useTranslation} from "../../PlattixReactCore/i18n";
import {faEllipsisH, faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import {ClickAwayListener, Popper} from "@mui/material";
//import {EnergytixPermissions} from "../../../Energytix/EnergytixPermissions";
import styled from "@emotion/styled";
import {Link} from "react-router-dom";

function filterContentCardProps(props: any) {
    return filterProps(props, ['Title']);
}

export interface ContentCardContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    asLegend?: boolean,
    asLegendItems?: boolean,
    sticky?: boolean,
}

export function PlattixCardContainer(props: React.PropsWithChildren<ContentCardContainerProps>) {
    return (
        <div {...filterProps(props, ['asLegend', 'asLegendItems', 'sticky'])}
             className={`row content-masonry ${props.className ?? ''} 
             ${!props.sticky ? 'content-legend-notSticky' : ''} 
             ${props.asLegend ? 'content-legend' : ''}
             ${props.asLegendItems ? 'content-legend-items' : ''} `}
        >
            {props.children}
        </div>
    );
}

PlattixCardContainer.defaultProps = {
    asLegend: false,
    sticky: true
}

export interface CardOptions {
    label: string,
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined,
    id?: string,
    permission?: string,
    Link?: string,
}

// export type SpanOptions = '1/-1' | '2/-1';

export interface ContentCardProps extends React.HTMLAttributes<HTMLDivElement> {
    header: string,
    options?: CardOptions[],
    collapsible?: boolean,
    info?: PlattixCardInfo,
    /// Show an overlay message over the whole card
    overlay?: React.ReactNode
    ContentCardBodyClassname?: string,
    customBodyContent?: boolean,
    
    /// Make card take up full width of container
    fullWidth?: boolean
}

export function PlattixCard(props: PropsWithChildren<ContentCardProps>) {    

    return <div {...filterProps(props, ['options', 'collapsible', 'header', 'fullWidth'])} className={`module-container ${props.className ?? ''} ${props.fullWidth ? "content-column-1_-1" : ""}`}>
        <ContentCardHeader
            Title={props.header}
            Collapsible={props.collapsible}
            options={props.options}
            info={props.info}
        />
        <ContentCardBody className={props.ContentCardBodyClassname ? props.ContentCardBodyClassname : 'module-block'}>
            {
                props.customBodyContent ?
                    props.children
                :
                    <ContentCardBodyContent>
                        {props.children}
                    </ContentCardBodyContent>
            }
        </ContentCardBody>
        {
            props.overlay &&
            <CardOverlay>
                {props.overlay}
            </CardOverlay>
        }
    </div>
}

const CardOverlay = styled('div')`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    backdrop-filter: blur(1px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--marginSides1);
    border-radius: 15px;
    
    * {
        color: white;
    }
    
`

export function ContentCard(props: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
    return (
        <div {...filterContentCardProps(props)} className={`module-container ${props.className ?? ''}`}>
            {props.children}
        </div>
    );
}

export type PlattixCardInfo = {
    header: string,
    body: string | JSX.Element
}

export interface ContentCardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    Title: string,
    Collapsible?: boolean,
    options?: CardOptions[],
    OptionsState?: boolean,
    OptionsOnClick?: (e: any) => void,
    info?: PlattixCardInfo
}

export function ContentCardHeader(props: React.PropsWithChildren<ContentCardHeaderProps>) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    
    const {t} = useTranslation();

    const open = !!anchorEl;

    const toggleOptions = (e) => {
        e.stopPropagation();
        setAnchorEl(anchorEl ? null : e.currentTarget);
    }

    const clickAwayHandler = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div className="module-header">
                {
                    props.Collapsible &&
                    <span className="module-colapse" onClick={(e) => moduleColapse(e.currentTarget)}>
                        {/*<FontAwesomeIcon icon={faCaretDown} className='module-icon' />*/}
                        <i className="fas fa-caret-down module-icon"></i>
                    </span>
                }
                <h5>{props.Title} </h5>
                {
                    props.info &&
                    <div className="module-info">
                    <span className="module-info-button" onClick={() => setIsOpen(true)}>
                        <FontAwesomeIcon icon={faQuestionCircle} className='module-icon'/>
                    </span>
                    </div>
                }
                {
                    props.options &&
                    <span className="module-options" onClick={toggleOptions}>
                        <FontAwesomeIcon icon={faEllipsisH} className='module-icon'/>
                    </span>
                }
            </div>
            {
                props.info &&
                <Modal
                    show={modalIsOpen}
                    onClose={() => setIsOpen(false)}
                    title={props.info.header}
                    showConfirmButton={false}
                    cancelButtonText={t('Close')}
                >
                    {props.info.body}
                </Modal>
            }
            {
                props.options &&
                <ClickAwayListener onClickAway={clickAwayHandler}>
                    <Popper
                        // id={props.opened ? 'simple-popper' : undefined}
                        open={open}
                        anchorEl={anchorEl}
                        placement="bottom-end"
                        disablePortal={false}
                    >
                        <div {...filterProps(props, ['anchorEl', 'opened', 'clickedAway'])} className={`module-options-dropdown ${props.className ?? ''} ${open && 'module-options-active'}`}>
                            <ul>
                                {props.options?.map(o => {
                                    /* TODO Jens Colman */
                     /* 
                              if (o.permission)
                              return <EnergytixPermissions permission={o.permission}>
                                  <ContentCardOption
                                      key={o.label}
                                      description={o.label}
                                      onClick={o.onClick}
                                      id={o.id}
                                  />
                              </EnergytixPermissions>*/

                                    return <ContentCardOption
                                        key={o.label}
                                        description={o.label}
                                        onClick={o.onClick}
                                        Link={o.Link}
                                        id={o.id}
                                    />
                                })}
                            </ul>
                        </div>
                    </Popper>
                </ClickAwayListener>
            }
        </>
    );
}


export interface ContentCardBodyProps extends React.HTMLAttributes<HTMLDivElement> {
}

export function ContentCardBody(props: React.PropsWithChildren<ContentCardBodyProps>) {
    return (
        <div {...filterContentCardProps(props)} className={`module-content ${props.className ?? ''}`}>
            {props.children}
        </div>
    );
}

export interface ContentCardBodyContentProps extends React.HTMLAttributes<HTMLDivElement> {
}

export function ContentCardBodyContent(props: React.PropsWithChildren<ContentCardBodyContentProps>) {
    return (
        <div {...filterContentCardProps(props)} className={`tab-content ${props.className ?? ''}`}>
            {props.children}
        </div>
    );
}

export interface ContentCardBodyContentSidebarProps extends React.HTMLAttributes<HTMLDivElement> {
}

export function ContentCardBodyContentSidebar(props: React.PropsWithChildren<ContentCardBodyContentSidebarProps>) {
    return (
        <div {...filterContentCardProps(props)} className={`module-netwerk-sidebar ${props.className ?? ''}`}
             onMouseMove={(e) => sideBarHover(e.currentTarget)}
             onMouseLeave={(e) => sideBarHoverOut(e.currentTarget)}>
            <ul>
                {/*@{await Html.RenderPartialAsync("Partials/_ServicePointAsset_TabNavPills", Model); }*/}
                {props.children}
            </ul>
        </div>
    );
}

export interface ContentCardBodyContentSidebarItemProps extends React.HTMLAttributes<HTMLDivElement> {
    Title: string,
    TabName: string,
    Selected?: boolean,
    Icon: IconName | [IconPrefix, IconName] | IconLookup,
    Show?: boolean
}

export function ContentCardBodyContentSidebarItem(props: React.PropsWithChildren<ContentCardBodyContentSidebarItemProps>) {
    if (!props.Show) return <></>

    return (
        <li className="nav-item nav-netwerk-item">
            <a className={`nav-link active text-uppercase ${!!props.Selected ? 'active' : ''}`}
               id={`${props.TabName}-tab`}
               data-toggle="tab"
               href="#"
               role="tab"
               aria-controls={props.TabName}
               aria-selected={props.Selected || false}
               onClick={(e) => netwerkTab(e.currentTarget)}>
                <FontAwesomeIcon icon={props.Icon} className='module-netwerk-icon'/>
                <span id="module-netwerk-sidebar-text" className="module-netwerk-sidebar-text">{props.Title}</span>
            </a>
        </li>
    );
}

ContentCardBodyContentSidebarItem.defaultProps = {
    Show: true
}

export interface ContentCardBodyContentSidebarItemContentProps extends React.HTMLAttributes<HTMLDivElement> {
    TabName: string,
    SequenceNumber: string,
    Selected?: boolean,
    Show?: boolean
}

export function ContentCardBodyContentSidebarItemContent(props: React.PropsWithChildren<ContentCardBodyContentSidebarItemContentProps>) {
    if (!props.Show) return <></>

    return (
        <div className={`tab-pane fade ${!!props.Selected ? 'show active' : ''}`}
             id={`${props.TabName}_${props.SequenceNumber}`} role="tabpanel" aria-labelledby={`${props.TabName}-tab`}>
            {props.children}
        </div>
    );
}

ContentCardBodyContentSidebarItemContent.defaultProps = {
    Show: true
}

export interface ContentCardButtonsProps extends React.HTMLAttributes<HTMLDivElement> {
}

export function ContentCardButtons(props: React.PropsWithChildren<ContentCardButtonsProps>) {
    return (
        <div {...filterContentCardProps(props)} className={`module-content-tab-btns ${props.className ?? ''}`}>
            {props.children}
        </div>
    );
}

// export interface ContentCardOptionsProps extends React.HTMLAttributes<HTMLDivElement> {
//     anchorEl: any,
//     opened: boolean,
//     clickedAway: (e: any) => void,
// }
//
// export function ContentCardOptions(props: React.PropsWithChildren<ContentCardOptionsProps>) {    
//     return (
//         <ClickAwayListener onClickAway={props.clickedAway}>
//             <Popper
//                 // id={props.opened ? 'simple-popper' : undefined}
//                 open={props.opened}
//                 anchorEl={props.anchorEl}
//                 placement="bottom-end"
//             >
//                 <div {...filterProps(props, ['anchorEl', 'opened', 'clickedAway'])} className={`module-options-dropdown ${props.className ?? ''} ${props.opened && 'module-options-active'}`}>
//                     <ul>
//                         {props.children}
//                     </ul>
//                 </div>
//             </Popper>
//         </ClickAwayListener>
//     );
// }

export interface ContentCardOptionProps extends React.HTMLAttributes<HTMLDivElement> {
    description: string,
    Link?: string,
}

export function ContentCardOption(props: React.PropsWithChildren<ContentCardOptionProps>) {
    return (
        <li>
            {props.Link ?
                <Link {...filterContentCardProps(props)} to={props.Link}>{props.description}</Link>
                :
                <span {...filterContentCardProps(props)}>{props.description}</span>}
        </li>
    );
}

//interface ContentCardOptionsProps {
//    Options: {
//        Title: string,
//        Link?: string,
//    }[],
//}

//export function ContentCardOptions(props: React.PropsWithChildren<ContentCardOptionsProps>) {
//    return (

//        <div className="module-options-dropdown" id="module-options-dropdown">
//            <div>
//                <span className="module-options" onClick={(e) => moduleOptions(e.currentTarget)}>
//                    <i className="fas fa-ellipsis-h module-icon"></i>
//                </span>
//            </div>
//            <ul>
//                {props.Options.map((option, i) => <li key={option}>
//                    <span key={i}>{option.Title}</span>
//                </li>)}
//            </ul>
//        </div>
//    );
//}

{/*<ContentCardOptions Options={[{ Title: 'Title 1', Link: 'skdjfhskdjfh' }, { Title: 'Title 2', Link: 'oiuio' }]} />*/
}