import * as React from "react";
import i18n from '../../PlattixReactCore/i18n'
import {filterProps} from "../../util/ElementProperties";
import {logoutUser, userSelector} from "../../PlattixReactCore/UserSlice";
import {useAppDispatch, useAppSelector} from "../../PlattixReactCore/hooks";
import {Spinner} from "./Loader";

export type ButtonType = "button" | "submit" | "reset"

export interface ButtonParams {
    onClick?: React.MouseEventHandler<HTMLDivElement>,
    type?: ButtonType
}


// enum ButtonColors {
//     Green = 'content-btn-1',
//     Red = 'content-btn-2',
//     Transparent = 'content-btn-3',
//     Blue = 'content-btn-4',
//     Grey = 'content-btn-5'
// }

export enum ButtonColors {
    Confirm = 'content-btn-1',
    Edit = 'content-btn-4',
    Cancel = 'content-btn-5',
    Error = 'content-btn-2',
}

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    buttonColor?: ButtonColors,
    loading?: boolean,
    disabled?: boolean,
    disableOnLoad?: boolean,
    type?: ButtonType,
    form?: string
}

function filterButtonProps(props: any) {
    return filterProps(props, ['className', 'buttonColor', 'loading', 'disableOnLoad']);
}

export function Button(props: React.PropsWithChildren<ButtonProps>) {
    return (
            <button
                {...filterButtonProps(props)}
                type={props.type ?? "button"}
                disabled={props.disabled || (props.disableOnLoad && props.loading)}
                form={props.form}
                className={`content-btn ${props.buttonColor ?? ButtonColors.Edit} ${props.className ?? ''}`}
            >
                {props.loading && <Spinner size={"small"} style={{marginRight: "0.5em"}}/>}
                {props.children}
            </button>
    );
}

Button.defaultProps = {
    disableOnLoad: true
}

/**
 * Button to render in MUI Data Grid
 * @param props
 * @constructor
 */
export function GridButton(props: ButtonProps) {
    props = Object.assign({
        buttonColor: ButtonColors.Confirm,
        className: 'btn-irc-grid'
    }, props)
    return Button(props);
}

export function ConfirmButton(props: ButtonProps) {
    props = Object.assign({
        buttonColor: ButtonColors.Confirm,
        children: i18n.t('Confirm')
    }, props)
    return Button(props);
}

export function AddButton(props: ButtonProps) {
    props = Object.assign({
        buttonColor: ButtonColors.Confirm,
        children: i18n.t('Confirm')
    }, props)
    return Button(props);
}

export function CancelButton(props: ButtonProps) {
    props = Object.assign({
        buttonColor: ButtonColors.Cancel,
        children: i18n.t('Cancel')
    }, props)
    return Button(props);
}

export function EditButton(props: ButtonProps) {
    props = Object.assign({
        buttonColor: ButtonColors.Edit,
        children: i18n.t('Edit')
    }, props)
    return Button(props);
}

export function DeleteButton(props: ButtonProps) {
    props = Object.assign({
        buttonColor: ButtonColors.Error,
        children: i18n.t('Delete')
    }, props)
    return Button(props);
}

export function LogoutButton(props: ButtonProps) {
    const dispatch = useAppDispatch();
    const {user, userStatus} = useAppSelector(userSelector);

    function logout() {
        if (userStatus.isLoading || !userStatus.isLoggedIn) {
            return;
        }

        dispatch<any>(logoutUser())
    }

    return <Button onClick={logout}>{i18n.t('Logout')}</Button>
}

export function LogoutLink(props: React.HTMLAttributes<HTMLParagraphElement>) {
    const dispatch = useAppDispatch();
    const {user, userStatus} = useAppSelector(userSelector);

    function logout() {
        if (userStatus.isLoading || !userStatus.isLoggedIn) {
            return;
        }

        dispatch<any>(logoutUser())
    }

    return <p {...props} onClick={logout}>{i18n.t('Logout')}</p>
}

export interface ToggleButtonProps {
    onClick?: React.ChangeEventHandler<HTMLInputElement>,
    checked: boolean,
    readOnly?: boolean,
    loading?: boolean
}

export function ToggleButton(props: ToggleButtonProps) {
    return <label className="content-btn-switch">
        {props.loading && <Spinner size={"small"}/>}
        <input type="checkbox" onChange={props.onClick} readOnly={!props.onClick || props.readOnly || props.loading}
               className="asset-active-checkbox" checked={props.checked}/>
        <span className="content-btn-slider"/>
    </label>
}